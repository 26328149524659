const SignedInScopeTypeMap = {
  user: 'User',
  admin: 'Admin'
}

function install(Vue, options = {
  envScope: 'user'
}) {
  Vue.mixin({
    computed: {
      currentUser() {
        return this.$store.getters['currentUser']
      },

      isUserSignedIn() {
        return this.currentUser.type === SignedInScopeTypeMap[options.envScope]
      }
    },

    methods: {
      simpleFormat(text) {
        if (text) {
          return text.replace(/(?:\r\n|\r|\n)/g, '<br />')
        }
      }
    }
  })
}

export default install
