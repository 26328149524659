import * as types from './mutation-types'
import ShippingMethod from '../../resource_models/shipping_method'

export const all = ({
  dispatch,
  commit
}, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    ShippingMethod.all(options)
      .then(response => {
        commit(types.FETCH_SHIPPING_METHODS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: all,
          ref: {
            dispatch,
            commit
          },
          params: options
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const find = ({
  dispatch,
  commit
}, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    ShippingMethod.find(id)
      .then(response => {
        commit(types.GET_SHIPPING_METHOD_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: find,
          ref: {
            dispatch,
            commit
          },
          params: id
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const save = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model.save()
      .then(response => {
        if (model.isNewRecord()) {
          commit(types.ADD_SHIPPING_METHOD_SUCCESS, response)
        } else {
          commit(types.UPDATE_SHIPPING_METHOD_SUCCESS, response)
        }

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: save,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const destroy = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model.destroy()
      .then(response => {
        commit(types.DELETE_SHIPPING_METHOD_SUCCESS, model.id)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: destroy,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const toggle = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'toggle')

  return new Promise((resolve, reject) => {
    model.toggle()
      .then(response => {
        commit(types.UPDATE_SHIPPING_METHOD_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: toggle,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const fetchRateTypes = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'fetchRateTypes')

  return new Promise((resolve, reject) => {
    ShippingMethod.fetchRateTypes()
      .then(response => {
        commit(types.FETCH_RATE_TYPES_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: fetchRateTypes,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const fetchGateways = ({
  dispatch,
  commit
}) => {
  commit(types.API_REQUEST_START, 'fetchGateways')

  return new Promise((resolve, reject) => {
    ShippingMethod.fetchGateways()
      .then(response => {
        commit(types.FETCH_GATEWAYS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: fetchGateways,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const configGateway = ({
  dispatch,
  commit
}, gateway) => {
  commit(types.API_REQUEST_START, 'configGateway')

  return new Promise((resolve, reject) => {
    ShippingMethod.configGateway(gateway)
      .then(response => {
        commit(types.FETCH_GATEWAYS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: configGateway,
          ref: {
            dispatch,
            commit
          },
          params: gateway
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const removeGatewayConfigurations = ({
  dispatch,
  commit
}, gateway) => {
  commit(types.API_REQUEST_START, 'removeGatewayConfigurations')

  return new Promise((resolve, reject) => {
    ShippingMethod.removeGatewayConfigurations(gateway)
      .then(response => {
        commit(types.FETCH_GATEWAYS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: removeGatewayConfigurations,
          ref: {
            dispatch,
            commit
          },
          params: gateway
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({
  commit
}, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_SHIPPING_METHODS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({
  commit
}, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_SHIPPING_METHOD_SUCCESS, response)

    resolve(response)
  })
}
