import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'

const helpers = new MutationHelpers('category_systems')

export default {
  [types.FETCH_CATEGORY_SYSTEMS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_CATEGORY_SYSTEMS_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_CATEGORY_SYSTEM_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.ADD_CATEGORY_SYSTEM_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_CATEGORY_SYSTEM_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_CATEGORY_SYSTEM_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.FETCH_CATEGORIES_SUCCESS](state, response) {
    helpers.replaceSpecialEntities(state, response, 'category', 'category_system_categories')

    state.isCallingAPI = false
  },

  [types.ADD_CATEGORY_SUCCESS](state, response) {
    helpers.storeOneResourceToSpecialEntities(state, response, 'category', 'category_system_categories')
    helpers.pushResourceToSpecialResult(state, response, 'category', 'category_system_categories')

    state.isCallingAPI = false
  },

  [types.UPDATE_CATEGORY_SUCCESS](state, response) {
    helpers.storeOneResourceToSpecialEntities(state, response, 'category', 'category_system_categories')

    state.isCallingAPI = false
  },

  [types.DELETE_CATEGORY_SUCCESS](state, id) {
    helpers.removeOneResourceFromSpecialEntities(state, id, 'category')

    state.isCallingAPI = false
  },

  [types.GET_RELATED_CATEGORIES_SUCCESS](state, response) {
    helpers.storeSpecialResourcesToEntities(state, response, 'category', 'category_system_categories')

    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_SUCCESS](state, action = 'not provided') {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  }
}
