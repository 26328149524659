import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'site_config',
  attributes: [
    'meta_tags',
    'brand_short_video',
    'trackers',
    'mobile_quick_link_settings',
    'site_contacts',
    'notification_system',
    'rma'
  ],
  editableAttributes: [
    'meta_tags',
    'brand_short_video',
    'trackers',
    'mobile_quick_link_settings',
    'site_contacts',
    'notification_system'
  ]
}

export default class SiteConfig extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchMobileQuickLinks() {
    return axios.get(`${new this().apiBasePath()}/mobile_quick_links`)
  }

  updateMetaTags() {
    return axios.put(`${this.apiBasePath()}/meta_tags`, this.partialConfigRequestBody(this.meta_tags))
  }

  updateBrandShortVideo() {
    return axios.put(`${this.apiBasePath()}/brand_short_video`, this.partialConfigRequestBody(this.brand_short_video))
  }

  updateTrackers() {
    return axios.put(`${this.apiBasePath()}/trackers`, this.partialConfigRequestBody(this.trackers))
  }

  updateMobileQuickLinks() {
    return axios.put(
      `${this.apiBasePath()}/mobile_quick_links`,
      this.partialConfigRequestBody(this.mobile_quick_link_settings)
    )
  }

  updateSiteContacts() {
    return axios.put(`${this.apiBasePath()}/site_contacts`, this.partialConfigRequestBody(this.site_contacts))
  }

  updateNotificationSystem() {
    return axios.put(
      `${this.apiBasePath()}/notification_system`,
      this.partialConfigRequestBody(this.notification_system)
    )
  }

  updateRmaConfig() {
    return axios.put(`${this.apiBasePath()}/rma`, this.partialConfigRequestBody(this.rma))
  }

  // helpers

  /**
   * 僅更新特定 configs 時使用的 request body
   *
   * @param {Object} configs
   * @returns {Object} JSON:API 格式的 request body
   * @memberof SiteConfig
   */
  partialConfigRequestBody(configs) {
    return {
      data: {
        type: OPTIONS.resourceType,
        attributes: configs
      }
    }
  }
}
