import InfoLink from '../../resource_models/info_link'

export const isLoading = state => {
  return state.isCallingAPI
}

export const all = state => {
  return state.result.map(id => new InfoLink(state.entities[id]))
}

export const find = state => id => {
  return new InfoLink(state.entities[id])
}

export const meta = state => {
  return state.meta
}

export const errors = state => {
  return state.errors
}

export const pageSlots = state => {
  return state.pageSlots
}

export const allByType = state => type => {
  return state.result.map(id => new InfoLink(state.entities[id])).filter(infoLink => infoLink.use_type === type)
}
