import * as types from './mutation-types'
import OrderShipment from '../../resource_models/order_shipment'

export const all = ({
  dispatch,
  commit
}, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    OrderShipment.all(options)
      .then(response => {
        commit(types.FETCH_ORDER_SHIPMENTS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: all,
          ref: {
            dispatch,
            commit
          },
          params: options
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const find = ({
  dispatch,
  commit
}, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    OrderShipment.find(id)
      .then(response => {
        commit(types.GET_ORDER_SHIPMENT_SUCCESS, response)
        dispatch('orderItems/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: find,
          ref: {
            dispatch,
            commit
          },
          params: id
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

// export const save = ({
//   dispatch,
//   commit
// }, model) => {
//   commit(types.API_REQUEST_START, 'save')

//   return new Promise((resolve, reject) => {
//     model.save()
//       .then(response => {
//         if (model.isNewRecord()) {
//           commit(types.ADD_ORDER_SHIPMENT_SUCCESS, response)
//         } else {
//           commit(types.UPDATE_ORDER_SHIPMENT_SUCCESS, response)
//         }

//         resolve(response)
//       })
//       .catch(errors => {
//         model.errors.record(errors)
//         commit(types.API_REQUEST_FAIL, errors)
//         dispatch('errorMessageHandler', {
//           errors,
//           retryAction: save,
//           ref: {
//             dispatch,
//             commit
//           },
//           params: model
//         }, {
//           root: true
//         })

//         reject(errors)
//       })
//   })
// }

// export const destroy = ({
//   dispatch,
//   commit
// }, model) => {
//   commit(types.API_REQUEST_START, 'destroy')

//   return new Promise((resolve, reject) => {
//     model.destroy()
//       .then(response => {
//         commit(types.DELETE_ORDER_SHIPMENT_SUCCESS, model.id)

//         resolve(response)
//       })
//       .catch(errors => {
//         model.errors.record(errors)
//         commit(types.API_REQUEST_FAIL, errors)
//         dispatch('errorMessageHandler', {
//           errors,
//           retryAction: destroy,
//           ref: {
//             dispatch,
//             commit
//           },
//           params: model
//         }, {
//           root: true
//         })

//         reject(errors)
//       })
//   })
// }

export const tallyInBatches = ({
  dispatch,
  commit
}, shipmentIds) => {
  commit(types.API_REQUEST_START, 'tallyInBatches')

  return new Promise((resolve, reject) => {
    OrderShipment.tallyInBatches(shipmentIds)
      .then(response => {
        commit(types.GET_RELATED_ORDER_SHIPMENTS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: tallyInBatches,
          ref: {
            dispatch,
            commit
          },
          params: shipmentIds
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const tally = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'tally')

  return new Promise((resolve, reject) => {
    model.tally()
      .then(response => {
        commit(types.UPDATE_ORDER_SHIPMENT_SUCCESS, response)
        dispatch('orders/updateResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: tally,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const ship = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'ship')

  return new Promise((resolve, reject) => {
    model.ship()
      .then(response => {
        commit(types.UPDATE_ORDER_SHIPMENT_SUCCESS, response)
        dispatch('orders/updateResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: ship,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const createLogistic = ({
  dispatch,
  commit
}, {
  model,
  options
}) => {
  commit(types.API_REQUEST_START, 'createLogistic')

  return new Promise((resolve, reject) => {
    model.createLogistic(options)
      .then(response => {
        commit(types.UPDATE_ORDER_SHIPMENT_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: createLogistic,
          ref: {
            dispatch,
            commit
          },
          params: {
            model,
            options
          }
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const fetchLogisticLabelFormData = ({
  dispatch,
  commit
}, {
  model,
  options
}) => {
  return new Promise((resolve, reject) => {
    model.fetchLogisticLabelFormData(options)
      .then(response => {

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: fetchLogisticLabelFormData,
          ref: {
            dispatch,
            commit
          },
          params: {
            model,
            options
          }
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({
  commit
}, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_ORDER_SHIPMENTS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({
  commit
}, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_ORDER_SHIPMENT_SUCCESS, response)

    resolve(response)
  })
}
