export const FETCH_RETURN_AUTHORIZATIONS_SUCCESS = 'FETCH_RETURN_AUTHORIZATIONS_SUCCESS'
export const GET_RELATED_RETURN_AUTHORIZATIONS_SUCCESS = 'GET_RELATED_RETURN_AUTHORIZATIONS_SUCCESS'
export const GET_RETURN_AUTHORIZATION_SUCCESS = 'GET_RETURN_AUTHORIZATION_SUCCESS'
export const ADD_RETURN_AUTHORIZATION_SUCCESS = 'ADD_RETURN_AUTHORIZATION_SUCCESS'
export const UPDATE_RETURN_AUTHORIZATION_SUCCESS = 'UPDATE_RETURN_AUTHORIZATION_SUCCESS'
export const DELETE_RETURN_AUTHORIZATION_SUCCESS = 'DELETE_RETURN_AUTHORIZATION_SUCCESS'
export const FETCH_RETURN_AUTHORIZATION_ITEMS_SUCCESS = 'FETCH_RETURN_AUTHORIZATION_ITEMS_SUCCESS'
export const UPDATE_RETURN_AUTHORIZATION_ITEM_SUCCESS = 'UPDATE_RETURN_AUTHORIZATION_ITEM_SUCCESS'
export const FETCH_RETURN_AUTHORIZATION_REASONS_SUCCESS = 'FETCH_RETURN_AUTHORIZATION_REASONS_SUCCESS'
export const ADD_RETURN_AUTHORIZATION_REASON_SUCCESS = 'ADD_RETURN_AUTHORIZATION_REASON_SUCCESS'
export const UPDATE_RETURN_AUTHORIZATION_REASON_SUCCESS = 'UPDATE_RETURN_AUTHORIZATION_REASON_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
