<template lang="pug">

.vc-return-authorization-state-list
  .state-unit.rma-state(:class="returnAuthorization.stateType()")
    label.label {{ attributeLocaleText('return_authorization', 'state') }}
    span.state {{ aasmStateLocaleText('return_authorization', 'state', returnAuthorization.state) }}
  .state-unit.has-reimbursements.is-success(v-if="returnAuthorization.is_reimbursed")
    .icon
      i.fa.fa-check-circle
    label.state {{ attributeLocaleText('return_authorization', 'has_reimbursements') }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
