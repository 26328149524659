import * as types from './mutation-types'
import Order from '../../resource_models/order'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    Order.all(options)
      .then(response => {
        commit(types.FETCH_ORDERS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    Order.find(id)
      .then(response => {
        commit(types.GET_ORDER_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then(response => {
        if (model.isNewRecord()) {
          commit(types.ADD_ORDER_SUCCESS, response)
        } else {
          commit(types.UPDATE_ORDER_SUCCESS, response)
        }

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then(response => {
        commit(types.DELETE_ORDER_SUCCESS, model.id)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateRefundInfo = ({ dispatch, commit }, refund) => {
  commit(types.API_REQUEST_START, 'updateRefundInfo')

  return new Promise((resolve, reject) => {
    refund
      .save()
      .then(response => {
        commit(types.GET_ORDER_REFUND_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        refund.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateRefundInfo,
            ref: {
              dispatch,
              commit
            },
            params: refund
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const confirmRefund = ({ dispatch, commit }, refund) => {
  commit(types.API_REQUEST_START, 'refundConfirm')

  return new Promise((resolve, reject) => {
    refund
      .confirmRefund()
      .then(response => {
        commit(types.GET_ORDER_REFUND_SUCCESS, response)
        dispatch('reimbursements/getResourceFromRelationship', response, { root: true })

        resolve(response)
      })
      .catch(errors => {
        refund.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: confirmRefund,
            ref: {
              dispatch,
              commit
            },
            params: refund
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const makePayment = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'makePayment')

  return new Promise((resolve, reject) => {
    model
      .makePayment()
      .then(response => {
        commit(types.FETCH_PAYMENT_PARAMS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: makePayment,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const detailInfo = ({ dispatch, commit }, token) => {
  commit(types.API_REQUEST_START, 'detailInfo')

  return new Promise((resolve, reject) => {
    Order.detailInfo(token)
      .then(response => {
        commit(types.GET_ORDER_SUCCESS, response)
        commit(types.FETCH_ORDER_INVOICES_SUCCESS, response)
        commit(types.FETCH_ORDER_CREDIT_NOTES_SUCCESS, response)
        commit(types.FETCH_ORDER_REFUNDS_SUCCESS, response)
        dispatch('orderShipments/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('orderPayments/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('orderItems/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: detailInfo,
            ref: {
              dispatch,
              commit
            },
            params: token
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchComments = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchComments')

  return new Promise((resolve, reject) => {
    model
      .fetchComments(options)
      .then(response => {
        dispatch('comments/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchComments')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchComments,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const addComment = ({ dispatch, commit }, { model, content }) => {
  commit(types.API_REQUEST_START, 'addComment')

  return new Promise((resolve, reject) => {
    model
      .addComment(content)
      .then(response => {
        dispatch('comments/addResourceFromRelationship', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'addComment')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: addComment,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              content
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const approve = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'approve')

  return new Promise((resolve, reject) => {
    model
      .approve()
      .then(response => {
        commit(types.UPDATE_ORDER_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: approve,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const approveInBatches = ({ dispatch, commit }, orderIds) => {
  commit(types.API_REQUEST_START, 'approveInBatches')

  return new Promise((resolve, reject) => {
    Order.approveInBatches(orderIds)
      .then(response => {
        commit(types.GET_RELATED_ORDERS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: approveInBatches,
            ref: {
              dispatch,
              commit
            },
            params: orderIds
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const cancel = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'cancel')

  return new Promise((resolve, reject) => {
    model
      .cancel()
      .then(response => {
        commit(types.UPDATE_ORDER_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: cancel,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const expressMap = ({ dispatch, commit }, { model, options }) => {
  commit(types.API_REQUEST_START, 'expressMap')

  return new Promise((resolve, reject) => {
    model
      .expressMap(options)
      .then(response => {
        commit(types.FETCH_EXPRESS_MAP_PARAMS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: expressMap,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const guestUrl = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'guestUrl')

  return new Promise((resolve, reject) => {
    model
      .guestUrl()
      .then(response => {
        commit(types.API_REQUEST_SUCCESS, 'guestUrl')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: transferTo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const transferTo = ({ dispatch, commit }, { model, userId, reason }) => {
  commit(types.API_REQUEST_START, 'transferTo')

  return new Promise((resolve, reject) => {
    model
      .transferTo(userId, reason)
      .then(response => {
        commit(types.UPDATE_ORDER_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: guestUrl,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              userId,
              reason
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const editContactInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'editContactInfo')

  return new Promise((resolve, reject) => {
    model
      .editContactInfo()
      .then(response => {
        commit(types.UPDATE_ORDER_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: editContactInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const markAsRead = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'markAsRead')

  return new Promise((resolve, reject) => {
    model
      .markAsRead()
      .then(response => {
        commit(types.UPDATE_ORDER_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: markAsRead,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_ORDERS_SUCCESS, response)

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsWithReplace = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_ORDERS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_ORDER_SUCCESS, response)

    resolve(response)
  })
}

export const updateResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_UPDATED_ORDER_SUCCESS, response)

    resolve(response)
  })
}

export const receiveAdjustmentsFormRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_ORDER_ADJUSTMENTS_SUCCESS, response)

    resolve(response)
  })
}

export const receiveRefundsFormRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_ORDER_REFUNDS_SUCCESS, response)

    resolve(response)
  })
}

export const receiveInvoicesFormRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_ORDER_INVOICES_SUCCESS, response)

    resolve(response)
  })
}
