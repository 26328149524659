<template lang="pug">

.vc-order-price-detail
  .wrapper
    .detail-unit(:data-minus="false")
      .label {{ attributeLocaleText('order', 'item_total') }}
      .price(data-currency="TWD"
             :data-empty="order.item_total === 0") {{ order.displayPrice('item_total') }}
    .detail-unit(:data-minus="false")
      .label {{ attributeLocaleText('order', 'shipment_total') }}
      .price(data-currency="TWD"
             :data-empty="order.shipment_total === 0") {{ shipmentTotal }}
    .detail-unit(v-for="adjustment in adjustments"
                 :key="adjustment.id"
                 :data-minus="adjustment.amount < 0")
      .label {{ adjustment.title }}
      .price(data-currency="TWD") {{ adjustment.displayAmount() }}

    .order-total
      .label
        span {{ attributeLocaleText('order', 'total') }}
        //- br
        //- span.tax-note {{ attributeLocaleText('order', 'include_business_tax', { tax: order.displayPrice('tax_total') }) }}
      .price(data-currency="TWD") {{ order.displayPrice('total') }}

  //- .notice-message-unit
    .icon
      i.fa.fa-info-circle
    span 訂單達 $400 以上即可享免運費優惠。

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    adjustments() {
      // 不顯示贈品的 adjustment
      return this.$store.getters['orders/allAdjustments'].filter(
        adjustment => !this.order.gift_items.map(gift => gift.id).includes(String(adjustment.adjustable_id))
      )
    },

    shipmentTotal() {
      if (['cart', 'items_confirmed'].includes(this.order.order_state)) return this.actionLocaleText('calculating')
      return this.order.displayPrice('shipment_total')
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
