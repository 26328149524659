export const FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS'
export const GET_RELATED_PAYMENT_METHODS_SUCCESS = 'GET_RELATED_PAYMENT_METHODS_SUCCESS'
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS'
export const ADD_PAYMENT_METHOD_SUCCESS = 'ADD_PAYMENT_METHOD_SUCCESS'
export const UPDATE_PAYMENT_METHOD_SUCCESS = 'UPDATE_PAYMENT_METHOD_SUCCESS'
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS'
export const FETCH_GATEWAYS_SUCCESS = 'FETCH_GATEWAYS_SUCCESS'
export const FETCH_INVOICE_SERVICES_SUCCESS = 'FETCH_INVOICE_SERVICES_SUCCESS'
export const FETCH_INVOICE_FOR_PAYMENT_CONFIG_SUCCESS = 'FETCH_INVOICE_FOR_PAYMENT_CONFIG_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
