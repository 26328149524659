import UserCommonAddress from '../../resource_models/user_common_address'

export const isLoading = state => {
  return state.isCallingAPI
}

export const all = state => {
  return state.result.map(id => new UserCommonAddress(state.entities[id]))
}

export const allByLogisticType = state => (type = undefined) => {
  return state.result.map(id => new UserCommonAddress(state.entities[id])).filter(address => {
    return address.address.extra_data === type
  })
}

export const find = state => id => {
  return new UserCommonAddress(state.entities[id])
}

export const meta = state => {
  return state.meta
}

export const errors = state => {
  return state.errors
}
