import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'distribution_base_brand_ships',
  attributes: ['id', 'distribution_base_id', 'brand_id', 'excluded_products'],
  editableAttributes: []
}

export default class DistributionBaseBrandShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  fetchExcludedProducts(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/excluded_products?${FetchingDataOptionsService.call(options)}`)
  }

  fetchAvailableProducts(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/products?${FetchingDataOptionsService.call(options)}`)
  }

  removeProductFromExcludedProducts(product) {
    return axios.delete(`${this.apiBasePath()}/${this.id}/excluded_products/${product.id}`)
  }

  removeProductFromAvailableProducts(product) {
    return axios.delete(`${this.apiBasePath()}/${this.id}/products/${product.id}`)
  }
  // extra methods or helpers here...
}
