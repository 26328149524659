import * as types from './mutation-types'
import SalesEvent from '../../resource_models/sales_event'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    SalesEvent.all(options)
      .then(response => {
        commit(types.FETCH_SALES_EVENTS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    SalesEvent.find(id)
      .then(response => {
        commit(types.GET_SALES_EVENT_SUCCESS, response)
        dispatch('categorySystems/getCategoryFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then(response => {
        if (model.isNewRecord()) {
          commit(types.ADD_SALES_EVENT_SUCCESS, response)
        } else {
          commit(types.UPDATE_SALES_EVENT_SUCCESS, response)
        }

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then(response => {
        commit(types.DELETE_SALES_EVENT_SUCCESS, model.id)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateCover = ({ dispatch, commit }, { model, formData }) => {
  commit(types.API_REQUEST_START, 'updateCover')

  return new Promise((resolve, reject) => {
    model
      .updateCover(formData)
      .then(response => {
        commit(types.UPDATE_SALES_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateCover,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const removeCover = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'removeCover')

  return new Promise((resolve, reject) => {
    model
      .removeCover()
      .then(response => {
        commit(types.UPDATE_SALES_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: removeCover,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              formData
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggle = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggle')

  return new Promise((resolve, reject) => {
    model
      .toggle()
      .then(response => {
        commit(types.UPDATE_SALES_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggle,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const hide = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'hide')

  return new Promise((resolve, reject) => {
    model
      .hide()
      .then(response => {
        commit(types.UPDATE_SALES_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: hide,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const revokeToken = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'revokeToken')

  return new Promise((resolve, reject) => {
    model
      .revokeToken()
      .then(response => {
        commit(types.UPDATE_SALES_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: revokeToken,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createProductShips = ({ dispatch, commit }, { model, productIds }) => {
  commit(types.API_REQUEST_START, 'createProductShips')

  return new Promise((resolve, reject) => {
    model
      .createProductShips(productIds)
      .then(response => {
        commit(types.UPDATE_SALES_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productIds
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProductShips = ({ dispatch, commit }, { model, productShips }) => {
  commit(types.API_REQUEST_START, 'updateProductShips')

  return new Promise((resolve, reject) => {
    model
      .updateProductShips(productShips)
      .then(response => {
        commit(types.UPDATE_SALES_EVENT_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productShips
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const deleteProductShip = ({ dispatch, commit }, { model, productShip }) => {
  commit(types.API_REQUEST_START, 'deleteProductShips')

  return new Promise((resolve, reject) => {
    model
      .deleteProductShips(productShip)
      .then(response => {
        commit(types.API_REQUEST_SUCCESS, 'deleteProductShips')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: deleteProductShips,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productShip
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchProducts = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchProducts(options)
      .then(response => {
        if (options.replace) {
          dispatch('products/receiveResourcesFromRelationshipsWithReplace', response, {
            root: true
          })
        } else {
          dispatch('products/receiveResourcesFromRelationships', response, {
            root: true
          })
        }

        commit(types.API_REQUEST_SUCCESS, 'fetchProducts')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const findProduct = ({ dispatch, commit }, { model, productId }) => {
  commit(types.API_REQUEST_START, 'findProduct')

  return new Promise((resolve, reject) => {
    model
      .findProduct(productId)
      .then(response => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('productVariants/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('productOptionTypes/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch('productImages/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: findProduct,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productId
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_SALES_EVENTS_SUCCESS, response)

    resolve(response)
  })
}

export const receiveResourcesFromRelationshipsWithReplace = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.FETCH_SALES_EVENTS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_SALES_EVENT_SUCCESS, response)

    resolve(response)
  })
}
