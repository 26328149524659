<template lang="pug">

.vc-product-category-category-menu(v-if="topCategories.length > 0"
                                   :class="{ 'is-active': isMobileMenuShow }")
  .category-unit(v-for="category in topCategories"
                 :key="category.id")
    .inner-wrapper(:class="{ 'is-active': currentCategory && currentCategory.id === category.id }"
                   @click="selectCategory(category)")
      span.name {{ category.name }}
      .icon
        i.fa.fa-chevron-right

    transition(enter-active-class="animated fadeIn")
      .sub-categories(v-if="currentCategory && currentCategory.id === category.id")
        sub-category-unit(v-for="subCategory in currentSubCategories(category)"
                          :key="subCategory.id"
                          :sub-category="subCategory"
                          :current-sub-category="currentSubCategory"
                          @sub-category-selected="currentSubCategorySelectedHandler")

</template>

<script>
import SubCategoryUnit from './sub-category-unit.vue'

export default {
  components: {
    SubCategoryUnit
  },
  // mixins: [],
  props: {
    currentCategory: {
      type: Object,
      required: false
    },

    currentSubCategory: {
      type: Object,
      required: false
    },

    isMobileMenuShow: {
      type: Boolean,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    topCategories() {
      return this.$store.getters['productCategories/allTopLevel']
    }
  },
  // created() {},
  mounted() {
    this._ensureCategoriesLoaded()
  },

  methods: {
    currentSubCategories(selectedCategory) {
      return this.$store.getters['productCategories/all'].filter(
        category => String(category.parent_id) === selectedCategory.id
      )
    },

    selectCategory(category) {
      if (this.currentCategory && this.currentCategory.id === category.id) {
        this._tryUnselectTopCategory()
      } else {
        this.$emit('update:currentCategory', category)
      }
      this.$emit('update:currentSubCategory', undefined)
      this.$emit('update:isMobileMenuShow', false)
    },

    currentSubCategorySelectedHandler(subCategory) {
      this.$emit('update:currentSubCategory', subCategory)
      this.$emit('update:isMobileMenuShow', false)
    },

    _ensureCategoriesLoaded() {
      if (this.topCategories.length > 0) {
        this.$emit('categories-loaded')
      } else {
        this.$store
          .dispatch('productCategories/all', {
            sort: 'position'
          })
          .then(_ => {
            this.$emit('categories-loaded')
          })
      }
    },

    /**
     * 若已經沒有被選擇的子類別，則取消選擇主類別
     */
    _tryUnselectTopCategory() {
      if (!this.currentSubCategory) {
        this.$emit('update:currentCategory', undefined)
      }
    }
  }
}
</script>
