import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import DistributionBaseList from '../../components/distribution_base/list.vue'
import oddPagination from '../../components/common/odd-pagination.vue'

export default {
  name: 'distribution-bases-page-container',

  components: {
    DistributionBaseList,
    oddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'distributionBases',
      currentPage: 1,
      pageSize: 25,
      sortOrder: 'asc',
      sortField: 'location_id',
      brand: null,
      location: null
    }
  },

  computed: {
    additionalOptions() {
      return {
        brand: this.brand,
        location: this.location
      }
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    isBrandLoading() {
      return this.$store.getters['brands/isLoading']
    },

    isLocationLoading() {
      return this.locations.length === 0
    },

    distributionBases() {
      return this.$store.getters['distributionBases/all']
    },

    locations() {
      return this.$store.getters['distributionBases/allLocations']
    },

    brands() {
      return this.$store.getters['brands/all']
    }
  },

  // created() {},

  mounted() {
    this.fetchingInitialData()
    this.$store.dispatch('distributionBases/fetchLocations', {
      sort: 'id'
    })
    this.$store.dispatch('brands/all', {
      sort: 'name'
    })
  },

  methods: {
    checkAdditionalOptionsFromUrl() {
      return {
        brand: this.currentQueryString['brand'] || this.brand,
        location: this.currentQueryString['location'] || this.location
      }
    },

    updateQueryOptionsForAdditionalOptions(options) {
      // this.exampleValue = options.example
      this.brand = options.brand
      this.location = options.location
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      if (options.brand) result += `&brand=${options.brand}`
      if (options.location) result += `&location=${options.location}`

      return result
    },

    onBrandChange(value) {
      this.brand = value
      this.currentPage = 1

      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
    },

    onLocationChange(value) {
      this.location = value
      this.currentPage = 1

      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
    }
  }
}
