<template lang="pug">

.vc-product-tag-filter(v-if="productTags.length > 0")
  span.text
    | {{ copyLocaleText('tag_filter') }} :
  b-checkbox-button(v-for="tag in productTags"
                    :key="tag.id"
                    v-model="tags"
                    type="is-odd"
                    :native-value="tag.name"
                    @input="tagFilterUpdated")
    .icon
      i.fa.fa-tag
    span {{ tag.name }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    tagWith: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tags: [],
      initing: true
    }
  },

  watch: {
    tagWith() {
      if (this.initing) this.initTagFilters()
    }
  },

  computed: {
    productTags() {
      return this.$store.getters['productTags/all']
    }
  },

  created() {
    this.$store.dispatch('productTags/all')
  },
  // mounted() {},
  methods: {
    tagFilterUpdated(value) {
      this.$emit('tag-filter-updated', value)
    },

    initTagFilters() {
      let existTags = this.tagWith.split(',')

      this.tags = this.productTags
        .filter(tag => {
          return existTags.includes(tag.name)
        })
        .map(tag => tag.name)

      this.initing = false
    }
  }
}
</script>
