import * as types from './mutation-types'
import SiteConfig from '../../resource_models/site_config'

export const fetchConfigs = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchConfigs')

  return new Promise((resolve, reject) => {
    SiteConfig.all()
      .then(response => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchConfigs,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateMetaTags = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateMetaTags')

  return new Promise((resolve, reject) => {
    model
      .updateMetaTags()
      .then(response => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateMetaTags,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateBrandShortVideo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateBrandShortVideo')

  return new Promise((resolve, reject) => {
    model
      .updateBrandShortVideo()
      .then(response => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateBrandShortVideo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateTrackers = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateTrackers')

  return new Promise((resolve, reject) => {
    model
      .updateTrackers()
      .then(response => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateTrackers,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateNotificationSystem = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateNotificationSystem')

  return new Promise((resolve, reject) => {
    model
      .updateNotificationSystem()
      .then(response => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateNotificationSystem,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateMobileQuickLinks = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'updateMobileQuickLinks')

  return new Promise((resolve, reject) => {
    model
      .updateMobileQuickLinks()
      .then(response => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: updateMobileQuickLinks,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const updateSiteContacts = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'updateSiteContacts')

  return new Promise((resolve, reject) => {
    model
      .updateSiteContacts()
      .then(response => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: updateSiteContacts,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const fetchMobileQuickLinks = ({
  dispatch,
  commit
}) => {
  commit(types.API_REQUEST_START, 'fetchMobileQuickLinks')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchMobileQuickLinks()
      .then(response => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: fetchMobileQuickLinks,
          ref: {
            dispatch,
            commit
          },
          params: {}
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const updateRmaConfig = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateRmaConfig')

  return new Promise((resolve, reject) => {
    model
      .updateRmaConfig()
      .then(response => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateRmaConfig,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
