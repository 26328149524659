export const FETCH_PRODUCT_OPTION_VALUES_SUCCESS = 'FETCH_PRODUCT_OPTION_VALUES_SUCCESS'
export const GET_RELATED_PRODUCT_OPTION_VALUES_SUCCESS = 'GET_RELATED_PRODUCT_OPTION_VALUES_SUCCESS'
export const GET_PRODUCT_OPTION_VALUE_SUCCESS = 'GET_PRODUCT_OPTION_VALUE_SUCCESS'
export const ADD_PRODUCT_OPTION_VALUE_SUCCESS = 'ADD_PRODUCT_OPTION_VALUE_SUCCESS'
export const UPDATE_PRODUCT_OPTION_VALUE_SUCCESS = 'UPDATE_PRODUCT_OPTION_VALUE_SUCCESS'
export const DELETE_PRODUCT_OPTION_VALUE_SUCCESS = 'DELETE_PRODUCT_OPTION_VALUE_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
