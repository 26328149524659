<template lang="pug">

.vc-common-slideshow-container(v-if="hasBanner")
  .section-header.-white(v-if="hasTitle")
    .odd-container.has-padding
      h2.section-title
        .en {{ titleEn }}
        .zh {{ titleZh }}
  .slideshow-wrapper
    swiper(:options="swiperOption")
      swiper-slide(v-for="banner in banners"
                   :key="banner.id")
        a.link(:href="banner.url"
               target="_blank"
               ref="noopenner noreferrer"
               @click="sendPromoEvent(banner)")
          img.image(:src="isMobile ? banner.mobile_image.url || banner.image.url : banner.image.url")
      .swiper-pagination(slot="pagination")
  .decoration-stripe(v-if="hasDecoration")

</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

const MOBILE_IMAGE_BREAK_POINT = 769

export default {
  components: {
    swiper,
    swiperSlide
  },
  // mixins: [],

  props: {
    useType: {
      type: String,
      required: false,
      default: () => {
        return 'homepage'
      }
    },

    hasDecoration: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },

    titleEn: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    },

    titleZh: {
      type: String,
      required: false,
      default: () => {
        return ''
      }
    }
  },

  data() {
    return {
      swiperOption: {
        preventClicks: false,
        effect: 'fade',
        grabCursor: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (this.$store.getters['windowWidth'] || window.innerWidth) < MOBILE_IMAGE_BREAK_POINT
    },

    banners() {
      return this.$store.getters['banners/allByType'](this.useType)
    },

    hasBanner() {
      return this.banners.length > 0
    },

    hasTitle() {
      return this.titleEn && this.titleZh
    }
  },

  created() {
    this.$store.dispatch('banners/allByUseType', {
      filter: this.useType
    })
  },
  // mounted() {},
  methods: {
    sendPromoEvent(banner) {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:addPromo',
          {
            id: banner.id,
            name: banner.alt_text,
            creative: `${window.location.protocol}//${window.location.host}${banner.image.url}`,
            position: `${banner.use_type} banner ${banner.position}`
          }
        ],
        ['ec:setAction', 'promo_click'],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'Internal Promotions',
            eventAction: 'click',
            eventLabel: banner.alt_text
          }
        ]
      ])
    }
  }
}
</script>
