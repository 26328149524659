import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import ExtraQueryStringParseService from '../services/extra_query_string_parse_service.js'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'products',
  attributes: [
    'brand_id',
    'brand_name',
    'categories',
    'consumer_price',
    'cover',
    'created_at',
    'description',
    'discount_rate',
    'discounted_price',
    'id',
    'is_preorder',
    'name',
    'cost_price',
    'original_price',
    'properties',
    'sell_price',
    'updated_at',
    'width',
    'depth',
    'height',
    'weight',
    'sku',
    'master',
    'variants',
    'options',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'total_stock',
    'images',
    'is_sold_out',
    'shipping_category_id',
    'support_documents',
    'subtitle_1',
    'subtitle_2',
    'preserve_detail',
    'promotion_detail',
    'shipping_detail',
    'tag_list',
    'indicators',
    'card_discription',
    'is_available',
    'category_ids',
    'slug',
    'support_document_detail'
  ],
  editableAttributes: [
    'brand_id',
    'description',
    'is_preorder',
    'name',
    'price',
    'properties',
    'option_types',
    'uploaded_attachment_ids',
    'width',
    'depth',
    'height',
    'weight',
    'sku',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'shipping_category_id',
    'preserve_detail',
    'promotion_detail',
    'shipping_detail',
    'tag_list',
    'indicators',
    'card_discription',
    'is_available',
    'category_ids',
    'slug',
    'support_document_detail'
  ]
}

export default class Product extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }
  static all(options = {}) {
    return axios.get(
      `${new this().apiBasePath()}?${FetchingDataOptionsService.call(options)}${ExtraQueryStringParseService.call(
        options
      )}`
    )
  }

  static onSaleProducts(options) {
    return axios.get(
      `${new this().apiBasePath()}/on_sale?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  static hotProducts(options) {
    return axios.get(
      `${new this().apiBasePath()}/hot?${FetchingDataOptionsService.call(options)}${ExtraQueryStringParseService.call(
        options
      )}`
    )
  }

  static uploadImages(formData) {
    return axios.post(`${new this().apiBasePath()}/images`, formData)
  }

  static uploadAttachments(formData) {
    return axios.post(`${new this().apiBasePath({ withResourceType: false })}/editor_attachments`, formData)
  }

  fetchSupportDocuments() {
    return axios.get(`${this.apiBasePath()}/${this.id}/support_documents`)
  }

  fetchDistributionBases(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/distribution_bases?${FetchingDataOptionsService.call(options)}`)
  }

  uploadSupportDocument(formData) {
    return axios.post(`${this.apiBasePath()}/${this.id}/support_documents`, formData)
  }

  static bulkModifyCategory(data, options = {}) {
    return axios.put(`${new this().apiBasePath()}/bulk_modify_category?${FetchingDataOptionsService.call(options)}`, {
      ids: data.ids,
      target_id: data.targetId,
      replacement_id: data.replacementId
    })
  }

  collect() {
    return axios.post(`${this.apiBasePath()}/${this.id}/collect`)
  }

  uncollect() {
    return axios.delete(`${this.apiBasePath()}/${this.id}/collect`)
  }

  // helpers

  static sortableFields() {
    return ['created_at', 'name', 'master.sell_price_cents']
  }

  hasDiscount() {
    return this.discount_rate < 1
  }

  requestBody() {
    const priceColumns = ['cost', 'original', 'sell', 'discounted']

    // Price columns must be [Number]
    priceColumns.forEach(element => {
      this.price[element] = parseInt(this.price[element])
    })

    return {
      data: {
        type: OPTIONS.resourceType,
        attributes: this.attributes()
      }
    }
  }

  displayDiscountRate() {
    let rateNumber = this.discount_rate
      .toFixed(2)
      .toString()
      .split('.')[1]

    if (rateNumber.slice(-1) === '0') rateNumber = rateNumber.substr(0, 1)

    return `${rateNumber} ${I18n.t('activerecord.attributes.product.discount_unit')}`
  }

  displayPrice(
    price = 'sell',
    options = {
      toLocaleString: true
    }
  ) {
    let result = this[`${price}_price`] / 100

    if (!options.toLocaleString) return result

    if (result === 0) {
      result = '-'
    } else {
      result = result.toLocaleString()
    }

    return result
  }

  noPriceData() {
    return this.sell_price === 0
  }

  displaySize() {
    const properties = ['width', 'depth', 'height']
    let result = []

    properties.forEach(property => {
      if (this[property]) result.push(`${I18n.t(`activerecord.attributes.product.${property}`)} ${this[property]}`)
    })

    return result.join(' × ')
  }

  hasVariants() {
    return this.variants.length > 0
  }

  coverImageThumb() {
    return this.cover.square.url
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }
}
