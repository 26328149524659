<template lang="pug">

.vc-product-category-select-products
  .tabs-wrapper
    b-tabs(size="is-small"
           expanded
           @change="categoryChangeHandler")
      b-tab-item(v-for="category in categories"
                  :label="category.name"
                  :key="category.id")

  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  product-card(v-for="(product, index) in selectGoods"
               :key="product.id"
               :product="product"
               :index="index"
               :list-location="listLocation"
               @ga-impressed="impressedHandler")

</template>

<script>
import productImpressionMixin from '../mixins/product_impression_mixin.js'
import ProductCard from '../product/card.vue'

export default {
  components: {
    ProductCard
  },

  mixins: [productImpressionMixin],

  props: {
    productsCount: {
      type: Number,
      required: false,
      default: () => {
        return 8
      }
    }
  },

  data() {
    return {
      productIds: null,
      currentCategoryIndex: 0
    }
  },

  computed: {
    categories() {
      return this.$store.getters['productCategories/allTopLevel']
    },

    currentCategory() {
      return this.categories[this.currentCategoryIndex]
    },

    selectGoods() {
      if (!this.productIds) return []
      return this.$store.getters['products/all'].filter(product => this.productIds.includes(product.id))
    },

    listLocation() {
      return `Select Goods - ${this.currentCategory.name}`
    },

    isLoading() {
      return this.$store.getters['productCategories/isLoading']
    }
  },

  created() {
    this.$store
      .dispatch('productCategories/all', {
        sort: 'position'
      })
      .then(() => {
        return this.fetchCategorySelectGoodsFor(this.categories[this.currentCategoryIndex])
      })
  },

  // mounted() {},
  methods: {
    categoryChangeHandler(event) {
      this.currentCategoryIndex = event
      this.fetchCategorySelectGoodsFor(this.categories[event])
    },

    fetchCategorySelectGoodsFor(category) {
      return this.$store
        .dispatch('productCategories/selectGoods', {
          model: category,
          options: {
            pageSize: this.productsCount,
            pageNumber: 1
          }
        })
        .then(response => {
          this.productIds = response.data.data.map(product => product.id)
        })
    }
  }
}
</script>
