import * as types from './mutation-types'
import DistributionBase from '../../resource_models/distribution_base'
import DistributionBaseLocation from '../../resource_models/distribution_base_location'

export const all = ({
  dispatch,
  commit
}, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    DistributionBase.all(options)
      .then(response => {
        commit(types.FETCH_DISTRIBUTION_BASES_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: all,
          ref: {
            dispatch,
            commit
          },
          params: options
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const find = ({
  dispatch,
  commit
}, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    DistributionBase.find(id)
      .then(response => {
        commit(types.GET_DISTRIBUTION_BASE_SUCCESS, response)
        commit(types.FETCH_DISTRIBUTION_BASE_BRAND_SHIPS_SUCCESS, response)
        dispatch('brands/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: find,
          ref: {
            dispatch,
            commit
          },
          params: id
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const save = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model.save()
      .then(response => {
        if (model.isNewRecord()) {
          commit(types.ADD_DISTRIBUTION_BASE_SUCCESS, response)
        } else {
          commit(types.UPDATE_DISTRIBUTION_BASE_SUCCESS, response)
        }

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: save,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const destroy = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model.destroy()
      .then(response => {
        commit(types.DELETE_DISTRIBUTION_BASE_SUCCESS, model.id)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: destroy,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const fetchLocations = ({
  dispatch,
  commit
}, options) => {
  commit(types.API_REQUEST_START, 'fetchLocations')

  return new Promise((resolve, reject) => {
    DistributionBaseLocation.all(options)
      .then(response => {
        commit(types.FETCH_DISTRIBUTION_BASE_LOCATIONS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: fetchLocations,
          ref: {
            dispatch,
            commit
          },
          params: options
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const saveLocation = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'saveLocation')

  return new Promise((resolve, reject) => {
    model.save()
      .then(response => {
        if (model.isNewRecord()) {
          commit(types.ADD_DISTRIBUTION_BASE_LOCATION_SUCCESS, response)
        } else {
          commit(types.UPDATE_DISTRIBUTION_BASE_LOCATION_SUCCESS, response)
        }

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: save,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const destroyLocation = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'destroyLocation')

  return new Promise((resolve, reject) => {
    model.destroy()
      .then(response => {
        commit(types.DELETE_DISTRIBUTION_BASE_LOCATION_SUCCESS, model.id)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: destroyLocation,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const fetchBrands = ({
  dispatch,
  commit
}, {
  model,
  options
}) => {
  commit(types.API_REQUEST_START, 'fetchBrands')

  return new Promise((resolve, reject) => {
    model.fetchBrands(options)
      .then(response => {
        commit(types.API_REQUEST_SUCCESS, 'fetchBrands')
        dispatch('brands/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: fetchBrands,
          ref: {
            dispatch,
            commit
          },
          params: {
            model,
            options
          }
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const addBrandShip = ({
  dispatch,
  commit
}, {
  model,
  brand
}) => {
  commit(types.API_REQUEST_START, 'addBrandShip')

  return new Promise((resolve, reject) => {
    model.addBrandShip(brand)
      .then(response => {
        commit(types.UPDATE_DISTRIBUTION_BASE_SUCCESS, response)
        commit(types.FETCH_DISTRIBUTION_BASE_BRAND_SHIPS_SUCCESS, response)
        dispatch('brands/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: addBrandShip,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const destroyBrandShip = ({
  dispatch,
  commit
}, model) => {
  commit(types.API_REQUEST_START, 'destroyBrandShip')

  return new Promise((resolve, reject) => {
    model.destroy()
      .then(response => {
        commit(types.DELETE_DISTRIBUTION_BASE_BRAND_SHIP_SUCCESS, {
          model,
          response
        })

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: destroyBrandShip,
          ref: {
            dispatch,
            commit
          },
          params: model
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

/**
 * 取得已排除的品牌商品
 *
 * @param {*} model DistributionBaseBrandShip object
 * @returns
 */
export const fetchExcludedProducts = ({
  dispatch,
  commit
}, {
  model,
  options
}) => {
  commit(types.API_REQUEST_START, 'fetchExcludedProducts')

  return new Promise((resolve, reject) => {
    model.fetchExcludedProducts(options)
      .then(response => {
        dispatch('products/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchExcludedProducts')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: fetchExcludedProducts,
          ref: {
            dispatch,
            commit
          },
          params: {
            model,
            options
          }
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

/**
 * 取得尚未排除的品牌商品
 *
 * @param {*} model DistributionBaseBrandShip object
 * @returns
 */
export const fetchAvailableProducts = ({
  dispatch,
  commit
}, {
  model,
  options
}) => {
  commit(types.API_REQUEST_START, 'fetchAvailableProducts')

  return new Promise((resolve, reject) => {
    model.fetchAvailableProducts(options)
      .then(response => {
        dispatch('products/receiveResourcesFromRelationshipsWithReplace', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'fetchAvailableProducts')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: fetchAvailableProducts,
          ref: {
            dispatch,
            commit
          },
          params: {
            model,
            options
          }
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const removeProductFromExcludedProducts = ({
  dispatch,
  commit
}, {
  model,
  product
}) => {
  commit(types.API_REQUEST_START, 'removeProductFromExcludedProducts')

  return new Promise((resolve, reject) => {
    model.removeProductFromExcludedProducts(product)
      .then(response => {
        commit(types.API_REQUEST_SUCCESS, 'removeProductFromExcludedProducts')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: removeProductFromExcludedProducts,
          ref: {
            dispatch,
            commit
          },
          params: {
            model,
            product
          }
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const removeProductFromAvailableProducts = ({
  dispatch,
  commit
}, {
  model,
  product
}) => {
  commit(types.API_REQUEST_START, 'removeProductFromAvailableProducts')

  return new Promise((resolve, reject) => {
    model.removeProductFromAvailableProducts(product)
      .then(response => {
        commit(types.API_REQUEST_SUCCESS, 'removeProductFromAvailableProducts')

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch('errorMessageHandler', {
          errors,
          retryAction: removeProductFromAvailableProducts,
          ref: {
            dispatch,
            commit
          },
          params: {
            model,
            product
          }
        }, {
          root: true
        })

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({
  commit
}, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_DISTRIBUTION_BASES_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({
  commit
}, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_DISTRIBUTION_BASE_SUCCESS, response)

    resolve(response)
  })
}
