export default {
  // components: {},

  props: {
    brand: {
      type: Object,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    logoUrl() {
      return this.brand.logo.url
    },

    brandPath() {
      return `/brands/${this.brand.slug || this.brand.id}`
    }
  }

  // methods: {}
}
