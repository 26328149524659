import ShoppingCartService from '../../../shared/services/shopping_cart_service.js'
import CheckoutStepsParseService from '../../../shared/services/checkout_steps_parse_service.js'
import CheckoutSteps from '../components/order/checkout-steps.vue'
import CartConfirmation from '../components/order/cart-confirmation.vue'
import ShippingAndPaymentMethod from '../components/order/shipping-and-payment-method.vue'
import FinalConfirmation from '../components/order/final-confirmation.vue'

export default {
  name: 'cart-page-container',

  components: {
    CheckoutSteps,
    CartConfirmation,
    ShippingAndPaymentMethod,
    FinalConfirmation
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      currentStep: 0,
      originalStep: 0,
      cartService: null,
      isCurrentOrderLoading: false
    }
  },

  computed: {
    cartItems() {
      return this.$store.getters['orderItems/fromCurrentOrder']()
    },

    currentOrder() {
      return this.$store.getters['orders/find'](this.$store.getters['orderItems/currentOrderId'])
    },

    componentOfCurrentStep() {
      const steps = [null, 'cart-confirmation', 'shipping-and-payment-method', 'final-confirmation']

      return steps[this.currentStep]
    }
  },

  watch: {
    cartItemsCount(newValue) {
      if (newValue === 0) Turbolinks.visit('/')
    },

    'currentOrder.order_state': {
      handler() {
        let step = CheckoutStepsParseService.call(this.currentOrder)
        this.currentStep = step
        this.originalStep = step
      }
    }
  },

  created() {
    this.cartService = new ShoppingCartService(this.$localforage, this.$store)
    this.isCurrentOrderLoading = true

    this.cartService
      .fetchCurrentOrder({
        force: true
      })
      .then(() => {
        this.isCurrentOrderLoading = false
      })
      .then(() => {
        if (this.cartItems.length === 0) {
          this.$localforage.getItem('redirect-path').then(path => {
            Turbolinks.visit((path == 'undefined' ? null : path) || '/?cart_empty=1')
            this.$store.dispatch('addFlashMessage', ['notice', this.messageLocaleText('cart_is_empty_now')])
          })
        }
      })
      .catch(errors => {
        this.isCurrentOrderLoading = false
      })
  }

  // mounted() {},

  // methods: {}
}
