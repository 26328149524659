<template lang="pug">

.vc-product-info-card
  .header-bar
    .info-column
      h1.name {{ product.name }}
      a.brand(:href="brandLink"
              v-if="product.brand_id") {{ product.brand_name }}
      .spec-descriotion(v-if="isVariantsLoaded && !!selectedVariant.description")
        .inner-wrapper
          | {{ selectedVariant.description }}

  .options(v-if="variants.length > 0")
    .variant-selector.option-unit(v-if="variants.length > 1")
      .label {{ attributeLocaleText('order/item', 'variant') }}
      b-dropdown.content(v-model="selectedVariantId"
                 @change="variantChangedHandler")
        .variants-dropdown(role="button"
                           slot="trigger")
          .variant-name {{ selectedVariant.displayName() }}
          .clickable-indicator
            .icon
              i.fa.fa-chevron-down

        b-dropdown-item(v-for="variant in variants"
                        :key="variant.id"
                        :value="variant.id")
          span {{ variant.name }}
          span.message(v-if="variant.stock < 1") {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}
    .quantity-selector.option-unit
      .label {{ attributeLocaleText('order/item', 'quantity') }}
      quantity-picker.content(:quantity.sync="quantity"
                              :current-stock="currentStock")

  .offers-info(v-if="variants.length > 0")
    .info-unit.price(:class="{ '-event-live': salesEvent.isLive() }")
      .label
        template(v-if="salesEvent.isLive()")
          countdown(:time="eventRemainingTime"
                    :interval="1000"
                    tag="span"
                    @end="countdownEndedHandler")
            template(slot-scope="props")
              span
                .icon
                  i.fa.fa-bolt
                span {{ copyLocaleText('event_price') }}{{ copyLocaleText('remaining_time_counter', { day: props.days, hour: props.hours, minute: props.minutes, second: props.seconds }) }}
        template(v-else)
          span {{ attributeLocaleText('product', 'price') }}
      .price
        span.type(data-currency="TWD") {{ selectedVariant.displayPrice('original') }}
        span.type(data-currency="TWD") {{ eventPrice.toLocaleString() }}
    .info-unit.price
      .label {{ attributeLocaleText('product', 'sub_total') }}
      .price
        span.type(data-currency="TWD") {{ subTotal }}

  .action-bar
    template(v-if="isPurchasable")
      .button.is-odd.is-outlined(:class="{ 'is-loading': isProcessing }"
                                 @click="buyNow") {{ actionLocaleText('buy_now') }}
      .button.is-odd(:class="{ 'is-loading': isProcessing }"
                     @click="addToCart") {{ actionLocaleText('add_to_cart') }}
    template(v-else)
      .button.is-light(disabled) {{ copyLocaleText('stock_status.no_stock') }}

</template>

<script>
import salesEventPricesMixin from '../mixins/sales_event_prices_mixin.js'
import productVariantSelectableMixin from '../mixins/product_variant_selectable_mixin.js'
import Form from 'odd-form_object'
import Countdown from '@chenfengyuan/vue-countdown'

export default {
  components: {
    Countdown
  },

  mixins: [productVariantSelectableMixin, salesEventPricesMixin],

  props: {
    variants: {
      type: Array,
      required: true
    }
  },

  // data() {
  //   return {}
  // },

  computed: {
    _eventPriceCompareTarget() {
      return this.selectedVariant
    },

    brandLink() {
      return `/brands/${this.product.brand_id}`
    },

    selectedVariant() {
      return this.variants.find(variant => variant.id === this.selectedVariantId)
    },

    requestBody() {
      return {
        data: {
          type: 'order_items',
          attributes: {
            variant_id: this.selectedEventPriceObject.variant_id,
            event_price_id: this.selectedEventPriceObject.id,
            quantity: this.quantity
          }
        }
      }
    },

    eventRemainingTime() {
      const currentTime = Math.floor(Date.now() / 1000)

      return (this.salesEvent.ended_at - currentTime) * 1000
    },

    subTotal() {
      return (this.eventPrice * this.quantity).toLocaleString()
    }
  },

  watch: {
    masterVariant(newValue) {
      this.selectedVariantId = newValue.id
    },

    selectedVariantId() {
      this.quantity = 1
    }
  },

  // created() {},
  mounted() {
    this._setPaddingBottom()
    this._initializeCartService()
  },

  methods: {
    variantChangedHandler() {
      this.$nextTick(() => this.$emit('variant-changed', this.selectedVariant))
    },

    _setPaddingBottom() {
      const actionBarHeight = this.$el.querySelector('.action-bar').offsetHeight

      this.$el.style.paddingBottom = `${actionBarHeight}px`
    },

    addToCart() {
      this._callAddToCartMethod()
        .then(() => {
          this.isProcessing = false
          this.quantity = 1
          this.selectedVariantId = this.masterVariant.id
        })
        .catch(errors => {
          this.isProcessing = false
        })
    },

    buyNow() {
      this._callAddToCartMethod()
        .then(_ => {
          this.$localforage.setItem('redirect-path', window.location.href)
          Turbolinks.visit('/cart')
        })
        .catch(errors => {
          this.isProcessing = false
        })
    },

    countdownEndedHandler() {
      setTimeout(() => {
        this.$emit('countdown-end')
      }, 3000)
    }
  }
}
</script>
