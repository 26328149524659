import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'

const helpers = new MutationHelpers('user_collections')

export default {
  [types.FETCH_USER_COLLECTIONS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_USER_COLLECTIONS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)
    state.currentCollections = response.data.data.map(collection => String(collection.attributes.product_id))

    state.isCallingAPI = false
  },

  [types.GET_USER_COLLECTIONS_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.ADD_USER_COLLECTIONS_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_USER_COLLECTIONS_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_USER_COLLECTIONS_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.STORE_USER_COLLECTIONS_FROM_LOCAL](state, collections) {
    state.currentCollections = collections
  },

  [types.SET_LATEST_UPDATED_AT_FROM_LOCAL](state, latestUpdatedAt) {
    state.meta = Object.assign({}, state.meta, {
      latest_updated_at: latestUpdatedAt
    })
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_SUCCESS](state, action = 'not provided') {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  }
}
