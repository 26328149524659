<template lang="pug">

.vc-dashboard-resource-links
  a.resource-unit(v-for="resource in resourcesList"
                  :href="resource.path")
    .icon
      i.fa(:class="resource.icon")
    .text
      span {{ resource.name }}
    //- .count
    //-   span {{ resource.count }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  // props: {},
  // data() {
  //   return {}
  // },
  computed: {
    resourcesList() {
      return [
        {
          name: '通知總覽',
          icon: 'fa-newspaper-o',
          path: '#/user/notifications'
        },
        {
          name: '我的收藏',
          icon: 'fa-heart',
          path: '/user/collections'
        },
        {
          name: '折價票券',
          icon: 'fa-ticket',
          path: '#/user/coupons'
        }
      ]
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
