import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'product_tags',
  attributes: ['id', 'name', 'created_at', 'updated_at'],
  editableAttributes: [
    'name'
  ]
}

export default class ProductTag extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
