<template lang="pug">

.vc-order-cart-confirmation.odd-container.has-padding
  b-loading(:active="isLoading")

  cart-item-list(:order="order"
                 :cart-items="cartItems"
                 :gift-items="giftItems"
                 :current-step="currentStep"
                 :cart-service="cartService")

  .form-container
    .form-column.-clean
      .section-label(:class="errors.errorClassAt('shipping_method_id')")
        | {{ modelNameLocaleText('shipping_method') }}
      .form-section(:class="errors.errorClassAt('shipping_method_id')")
        shipping-method-selector(:selected-shipping-method-id.sync="selectedShippingMethodId"
                                  :cart-items="cartItems")
      .form-section
        promo-code-checker(:order="order")

    .form-column.-clean
      .section-label {{ attributeLocaleText('order', 'price_detail') }}
      .form-section
        price-detail(:order="order")

  .cart-options
    back-to-store-button
    .button.is-odd.back-to-store.next-step(@click="confirmItems"
                                                :class="{ 'is-loading': isLoading }") {{ actionLocaleText('next_step_is', { content: copyLocaleText('address_and_payment_method') }) }}

</template>

<script>
import checkoutFlowMixin from '../mixins/checkout_flow_mixin.js'
import CartItemList from './cart-item-list.vue'
import ShippingMethodSelector from './shipping-method-selector.vue'
import PromoCodeChecker from './promo-code-checker.vue'
import BackToStoreButton from './back-to-store-button.vue'

export default {
  components: {
    CartItemList,
    ShippingMethodSelector,
    PromoCodeChecker,
    BackToStoreButton
  },

  mixins: [checkoutFlowMixin],
  // props: {},

  data() {
    return {
      selectedShippingMethodId: undefined
    }
  },

  watch: {
    selectedShippingMethodId() {
      this.errors.clear('shipping_method_id')
    }
  },

  computed: {
    giftItems() {
      return this.$store.getters['orderItems/fromCurrentOrder']('gift')
    }
  },
  // created() {},
  mounted() {
    if (this.order.shipments.length > 0) this.selectedShippingMethodId = this.shippingMethodFormOrderShipment.id

    this._trackCheckoutProgress()
  },

  methods: {
    backToStore() {
      this.$localforage.getItem('redirect-path').then(path => {
        Turbolinks.visit(path || '/')
      })
    },

    confirmItems() {
      if (this.order.order_state === 'cart') {
        this.cartService
          .confirmItems(this.selectedShippingMethodId)
          .then(() => {
            this._scrollToTop()
          })
          .catch(_ => {
            this._scrollToTop()
          })
      } else {
        this.cartService
          .confirmItems(this.selectedShippingMethodId)
          .then(() => {
            return this.$emit('update:currentStep', 2)
          })
          .then(() => {
            this._scrollToTop()
          })
      }
    }
  }
}
</script>
