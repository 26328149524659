import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import ExtraQueryStringParseService from '../services/extra_query_string_parse_service.js'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'product_categories',
  attributes: [
    'id',
    'name',
    'description',
    'parent',
    'parent_id',
    'sub_categories',
    'products',
    'products_count',
    'created_at',
    'updated_at',
    'position',
    'name_with_parents'
  ],
  editableAttributes: ['name', 'description', 'parent_id']
}

export default class ProductCategory extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  fetchProducts(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/products?${FetchingDataOptionsService.call(options)}${ExtraQueryStringParseService.call(options)}`)
  }

  selectGoods(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/select_goods?${FetchingDataOptionsService.call(options)}${ExtraQueryStringParseService.call(options)}`)
  }
  // extra methods or helpers here...

  hasSubCategories() {
    return this.sub_categories.length > 0
  }

  updatePosition(data, options = {}) {
    return axios.put(`${this.apiBasePath()}/${this.id}/update_position?${FetchingDataOptionsService.call(options)}`, {
      target_instance_id: data.targetInstanceId
    })
  }
}
