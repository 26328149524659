import Content from '../../resource_models/content'

export const isLoading = state => {
  return state.isCallingAPI
}

export const all = state => {
  return state.result.map(id => new Content(state.entities[id]))
}

export const find = state => id => {
  return new Content(state.entities[id])
}

export const meta = state => {
  return state.meta
}

export const errors = state => {
  return state.errors
}
