export const FETCH_CATEGORY_SYSTEMS_SUCCESS = 'FETCH_CATEGORY_SYSTEMS_SUCCESS'
export const GET_RELATED_CATEGORY_SYSTEMS_SUCCESS = 'GET_RELATED_CATEGORY_SYSTEMS_SUCCESS'
export const GET_CATEGORY_SYSTEM_SUCCESS = 'GET_CATEGORY_SYSTEM_SUCCESS'
export const ADD_CATEGORY_SYSTEM_SUCCESS = 'ADD_CATEGORY_SYSTEM_SUCCESS'
export const UPDATE_CATEGORY_SYSTEM_SUCCESS = 'UPDATE_CATEGORY_SYSTEM_SUCCESS'
export const DELETE_CATEGORY_SYSTEM_SUCCESS = 'DELETE_CATEGORY_SYSTEM_SUCCESS'
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const GET_RELATED_CATEGORIES_SUCCESS = 'GET_RELATED_CATEGORIES_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
