import orderBy from 'lodash.orderby'
import ProductSlideshow from '../../components/product/slideshow.vue'
import ProductInfoCard from '../../components/product/info-card.vue'
import ProductInfoCardMobile from '../../components/product/info-card-mobile.vue'
import ProductComments from '../../components/product/comments.vue'
import ProductDocumentList from '../../components/product/document-list.vue'
import DistributionBaseList from '../../components/distribution_base/list.vue'

export default {
  name: 'product-show-page-container',

  components: {
    ProductSlideshow,
    ProductInfoCard,
    ProductInfoCardMobile,
    ProductComments,
    ProductDocumentList,
    DistributionBaseList
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      productId: null,
      isInitializing: true
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['products/isLoading']
    },

    product() {
      return this.$store.getters['products/find'](this.productId)
    },

    supportDocuments() {
      return this.$store.getters['productSupportDocuments/all']
    },

    distributionBases() {
      return this.$store.getters['distributionBases/all']
    },

    images() {
      return orderBy(
        this.$store.getters['productImages/forProduct'](this.productId),
        [img => img.variant_id || ''],
        ['asc']
      )
    },

    variants() {
      return this.$store.getters['productVariants/forProduct'](this.productId)
    },

    hasSupportDocuments() {
      return !!this.product.support_document_detail || this.supportDocuments.length > 0
    }
  },

  // created() {},

  mounted() {
    this.productId = this.$el.dataset.product
    this.$store
      .dispatch('products/find', this.productId)
      .then(_ => {
        return this.$store.dispatch('products/fetchSupportDocuments', this.product)
      })
      // .then(_ => {
      //   return this.$store.dispatch('products/fetchDistributionBases', {
      //     model: this.product,
      //     options: {
      //       sort: 'location_id'
      //     }
      //   })
      // })
      .then(_ => {
        this.isInitializing = false
        this.$nextTick(() => {
          this._addLazyLoading()
        })
      })
  },

  methods: {
    _addLazyLoading() {
      lozad('.lozad', {
        load: el => {
          el.classList.add('loading', 'animated', 'zoomIn')
          el.src = el.dataset.src
          el.onload = function() {
            el.removeAttribute('data-src')
            el.classList.remove('loading')
            el.classList.add('loaded')
          }
        }
      }).observe()
    },

    variantChangedHandler(newVariant) {
      if (newVariant.images.length === 0) return

      const imageId = newVariant.images[0].id

      this.$refs.slider.slideToImage(imageId)
    },

    countdownEndedHandler() {
      this.$store.dispatch('products/find', this.productId)
    },

    onTabChange(index) {
      let tabComponent = this.$refs.tabs,
        clickedTabLabel = tabComponent.tabItems[index].label

      this.$store.dispatch('gaOperation', [['send', 'event', 'UX', 'tabChange', clickedTabLabel]])
    }
  }
}
