import productImpressionMixin from '../../components/mixins/product_impression_mixin.js'
import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import CategoryMenu from '../../components/product_category/category-menu.vue'
import SlideshowContainer from '../../components/common/slideshow-container.vue'
import SearchBox from '../../components/product/search-box.vue'
import SortButton from '../../components/product/sort-button.vue'
import ProductTagFilter from '../../components/product/tag-filter.vue'
import ProductCard from '../../components/product/card.vue'
import OddPagination from '../../components/common/odd-pagination.vue'
import squaresIcon from '../../../images/squares.svg'

import Product from '../../../../shared/resource_models/product.js'

export default {
  name: 'products-index-page-container',

  components: {
    CategoryMenu,
    SlideshowContainer,
    SearchBox,
    SortButton,
    ProductTagFilter,
    ProductCard,
    OddPagination
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin, productImpressionMixin],

  // props: {},

  data() {
    return {
      isMobileMenuShow: false,
      isInitializing: false,
      resourceType: 'products',
      currentPage: 1,
      pageSize: 9,
      // sortOrder: 'desc',
      sortField: 'created_at',
      currentFilter: 0,
      availableFilters: [],
      isSearchOptionsOpen: true,
      searchOptions: {
        variants_with_master_sku_or_brand_name_or_name_cont: ''
      },
      tagWith: '',
      currentCategory: undefined,
      currentSubCategory: undefined,
      selectedCategoryId: undefined
    }
  },

  computed: {
    squaresIcon() {
      return squaresIcon
    },

    isCategorySelected() {
      return this.selectedCategoryId !== undefined
    },

    sectionTitle() {
      if (this.isCategorySelected) {
        return this.currentCategory.name
        // } else if (this.searchOptions['variants_with_master_sku_or_brand_name_or_name_cont']) {
        //   return `[${this.searchOptions['variants_with_master_sku_or_brand_name_or_name_cont']}] ${this.copyLocaleText('search_result')}`
      } else {
        return this.copyLocaleText('all_products')
      }
    },

    isLoading() {
      return this.$store.getters['productCategories/isLoading']
    },

    allCategories() {
      return this.$store.getters['productCategories/all']
    },

    products() {
      return this.$store.getters['products/all']
    },

    additionalOptions() {
      return {
        category: this.selectedCategoryId,
        tag: this.tagWith
      }
    },

    listContainerOffsetTop() {
      return this.$el.offsetParent.offsetTop - 50 - 90
    },

    listLocation() {
      if (this.currentCategory) {
        let result = this.currentCategory.name
        if (this.currentSubCategory) result += ` > ${this.currentSubCategory.name}`

        return `Category - ${result}`
      } else {
        return 'Product Index'
      }
    }
  },

  watch: {
    currentCategory: {
      handler(newValue) {
        if (newValue === undefined) return (this.selectedCategoryId = undefined)
        if (newValue && !this.currentSubCategory) {
          this.selectedCategoryChangedHandler(newValue)
        }
      }
    },

    currentSubCategory: {
      handler(newValue) {
        this.selectedCategoryChangedHandler(newValue)
      }
    },

    selectedCategoryId: {
      handler(newValue, oldValue) {
        if (newValue == oldValue) return
        if (newValue === undefined) return this._backToAllProductsMode()
        // 如果 `isInitializing === true`，處正在初始化中，直接中斷並結束初始化狀態。
        // isInitializing 會在 categoriesLoadedHandler 被觸發時設定為 true。但若 URL 中不包含指定 category 的 params 則會在結束時被設定為 false
        if (this.isInitializing) {
          return (this.isInitializing = false)
        }

        this.currentPage = 1
        this.fetchData(this.currentOptions)
        this.updateQueryString(this.currentOptions)
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    checkAdditionalOptionsFromUrl() {
      return {
        category: this.currentQueryString['category'] || this.selectedCategoryId,
        tag: this.currentQueryString['tag'] || this.tagWith
      }
    },

    updateQueryOptionsForAdditionalOptions(options) {
      if (options.category) this.selectedCategoryId = parseInt(options.category)
      this.tagWith = options.tag
    },

    additionalOptionsToQueryString(options) {
      let result = ''

      if (options.category) result += `&category=${options.category}`
      if (options.tag) result += `&tag=${options.tag}`

      return result
    },

    openMobileMenu() {
      // this.isMobileMenuShow = true
      this.isMobileMenuShow = !this.isMobileMenuShow
    },

    categoriesLoadedHandler() {
      this.isInitializing = true
      this.fetchingInitialData()
      this._initializeStateFromQueryString()
    },

    selectedCategoryChangedHandler(newCategory) {
      this.searchOptions['variants_with_master_sku_or_brand_name_or_name_cont'] = ''

      if (newCategory) {
        // 若新的值不是 undefined（是 productCategory instance）則將 selectedCategoryId 重新賦值為 nerCategory.id
        this.selectedCategoryId = newCategory.id
      } else {
        // 若新的值是 undefined，已有選擇主類別的狀況下，將 selectedCategoryId 設定為當前主類別 ID，若沒有選擇的話則設定為 undefined
        this.selectedCategoryId = this.currentCategory ? this.currentCategory.id : undefined
      }
    },

    sortOptionUpdatedHandler(field, order) {
      this.onSort(field, order)
    },

    searchOptionUpdatedHandler(string) {
      this.searchOptions.variants_with_master_sku_or_brand_name_or_name_cont = string
      this.fetchData(this.currentOptions).then(() => {
        if (this.currentPage === 0) this.currentPage = 1
        this.updateQueryString(this.currentOptions)
      })
    },

    updateTagWith(tags) {
      this.tagWith = tags.join(',')

      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
    },

    fetchData(options) {
      this.currentPage = options.pageNumber
      if (this.selectedCategoryId) {
        return this._fetchCategoryProducts(options)
      } else {
        return this._fetchHotProducts(options)
      }
    },

    _initializeStateFromQueryString() {
      if (this.selectedCategoryId) {
        const newCategory = this.allCategories.find(category => category.id === String(this.selectedCategoryId))

        if (newCategory.parent_id) {
          this.currentSubCategory = newCategory
          this.currentCategory = this.allCategories.find(category => category.id === String(newCategory.parent_id))
        } else {
          this.currentCategory = newCategory
        }
      } else {
        this.isInitializing = false
      }
    },

    _fetchCategoryProducts(options) {
      return this.$store.dispatch(`productCategories/fetchProducts`, {
        model: this.$store.getters['productCategories/find'](this.selectedCategoryId),
        options: Object.assign(options, {
          sub_categories_included: true
        })
      })
    },

    _fetchHotProducts(options) {
      return this.$store.dispatch(
        'products/hotProducts',
        Object.assign(options, {
          replace: true
        })
      )
    },

    _backToAllProductsMode() {
      this.currentPage = 1
      this.fetchData(this.currentOptions)
      this.updateQueryString(this.currentOptions)
    }
  }
}
