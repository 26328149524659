import PaymentMethod from '../../resource_models/payment_method'

export const isLoading = state => {
  return state.isCallingAPI
}

export const all = state => {
  return state.result.map(id => new PaymentMethod(state.entities[id]))
}

export const allEnabled = state => {
  return all(state).filter(paymentMethod => paymentMethod.enabled)
}

export const find = state => id => {
  return new PaymentMethod(state.entities[id])
}

export const gateways = state => {
  return state.gateways
}

export const invoiceServices = state => {
  return state.invoiceServices
}

export const isProvideInvoiceForPayment = state => {
  return state.isProvideInvoiceForPayment
}

export const meta = state => {
  return state.meta
}

export const errors = state => {
  return state.errors
}
