<template lang="pug">

.vc-sales-event-card
  .inner
    .cover-wrapper
      img.cover(:src="salesEvent.cover.url")
    .card-header
      h2.name {{ salesEvent.name }}
      .period
        span.label
          i.fa.fa-clock-o
          span {{ attributeLocaleText('sales_event', 'event_period') }}
        span.time.started-at {{ dateTimeLocaleText(salesEvent.started_at, 'YYYY-MM-DD HH:mm') }}
        span.time.ended-at {{ dateTimeLocaleText(salesEvent.ended_at, 'YYYY-MM-DD HH:mm') }}
    .countdown-wrapper(:class="{ 'is-live': salesEvent.isLive() }")
      .label {{ countdownLabel }}
      .counter
        .icon
          i.fa.fa-bolt
        countdown(:time="countdownTime"
                  :interval="1000")
          template(slot-scope="props")
            span.counter {{ copyLocaleText(countdownCopyKey, { day: props.days, hour: props.hours, minute: props.minutes, second: props.seconds }) }}
    .card-body
      .summary(v-html="simpleFormat(salesEvent.summary)")
      .options
        a.button.is-outlined(:href="`/sales_events/${salesEvent.id}`"
                             :class="{ 'is-odd': salesEvent.isLive(), 'is-default': !salesEvent.isLive() }")
          .icon
            i.fa(:class="actionButtonIcon")
          span {{ actionButtonText }}

</template>

<script>
import salesEventMixin from '../mixins/sales_event_mixin.js'

export default {
  // components: {},
  mixins: [salesEventMixin],

  props: {
    salesEvent: {
      type: Object,
      required: true
    }
  }
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
