import Order from '../../resource_models/order.js'
import Adjustment from '../../resource_models/adjustment.js'
import OrderRefund from '../../resource_models/order_refund.js'

export const isLoading = state => {
  return state.isCallingAPI
}

export const all = state => {
  return state.result.map(id => new Order(state.entities[id]))
}

export const allAdjustments = state => {
  return state.adjustmentResult.map(id => new Adjustment(state.adjustmentEntities[id]))
}

export const allRefunds = state => {
  return state.refundResult.map(id => new OrderRefund(state.refundEntities[id]))
}

export const findInvoice = state => id => {
  return state.invoiceEntities[id]
}

export const allCreditNotes = state => {
  return state.creditNoteResult.map(id => state.creditNoteEntities[id])
}

export const findAdjustmentFor = state => (type, id) => {
  return allAdjustments(state).find(
    adjustment => adjustment.adjustable_type === type && adjustment.adjustable_id === Number(id)
  )
}

export const paymentParams = state => {
  return state.paymentParams
}

export const gatewayApiBase = state => {
  return state.gatewayApiBase
}

export const expressMapParams = state => {
  return state.expressMapParams
}

export const expressMapApiBase = state => {
  return state.expressMapApiBase
}

export const find = state => id => {
  return new Order(state.entities[id])
}

export const meta = state => {
  return state.meta
}

export const errors = state => {
  return state.errors
}
