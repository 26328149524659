import * as types from './mutation-types'
import ReturnAuthorization from '../../resource_models/return_authorization'
import ReturnAuthorizationReason from '../../resource_models/return_authorization_reason.js'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    ReturnAuthorization.all(options)
      .then(response => {
        commit(types.FETCH_RETURN_AUTHORIZATIONS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    ReturnAuthorization.find(id)
      .then(response => {
        commit(types.GET_RETURN_AUTHORIZATION_SUCCESS, response)
        dispatch('productVariants/receiveResourcesFromRelationships', response, { root: true })
        dispatch('reimbursements/receiveResourcesFromRelationships', response, { root: true })
        dispatch('orders/receiveRefundsFormRelationship', response, { root: true })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then(response => {
        if (model.isNewRecord()) {
          commit(types.ADD_RETURN_AUTHORIZATION_SUCCESS, response)
        } else {
          commit(types.UPDATE_RETURN_AUTHORIZATION_SUCCESS, response)
        }

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then(response => {
        commit(types.DELETE_RETURN_AUTHORIZATION_SUCCESS, model.id)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const accept = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'accept')

  return new Promise((resolve, reject) => {
    model
      .accept()
      .then(response => {
        commit(types.UPDATE_RETURN_AUTHORIZATION_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: accept,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const cancel = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'cancel')

  return new Promise((resolve, reject) => {
    model
      .cancel()
      .then(response => {
        commit(types.UPDATE_RETURN_AUTHORIZATION_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: cancel,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createReimbursement = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'createReimbursement')

  return new Promise((resolve, reject) => {
    model
      .createReimbursement()
      .then(response => {
        commit(types.GET_RETURN_AUTHORIZATION_SUCCESS, response)
        dispatch('productVariants/receiveResourcesFromRelationships', response, { root: true })
        dispatch('reimbursements/receiveResourcesFromRelationships', response, { root: true })
        dispatch('orders/receiveRefundsFormRelationship', response, { root: true })

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createReimbursement,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchReasons = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'fetchReasons')

  return new Promise((resolve, reject) => {
    ReturnAuthorizationReason.all(options)
      .then(response => {
        commit(types.FETCH_RETURN_AUTHORIZATION_REASONS_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchReasons,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const saveReason = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'saveReason')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then(response => {
        if (model.isNewRecord()) {
          commit(types.ADD_RETURN_AUTHORIZATION_REASON_SUCCESS, response)
        } else {
          commit(types.UPDATE_RETURN_AUTHORIZATION_REASON_SUCCESS, response)
        }

        resolve(response)
      })
      .catch(errors => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleReason = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'toggleReason')

  return new Promise((resolve, reject) => {
    model
      .toggle()
      .then(response => {
        commit(types.UPDATE_RETURN_AUTHORIZATION_REASON_SUCCESS, response)

        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleReason,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const findOrderItemViaReturnItem = ({ dispatch, commit }, returnItem) => {
  commit(types.API_REQUEST_START, 'findOrderItemViaReturnItem')

  return new Promise((resolve, reject) => {
    returnItem
      .findOrderItem()
      .then(response => {
        dispatch('orderItems/getResourceFromRelationship', response, { root: true })
        commit(types.API_REQUEST_SUCCESS, 'findOrderItemViaReturnItem')
        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: findOrderItemViaReturnItem,
            ref: {
              dispatch,
              commit
            },
            params: returnItem
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchAllOrderAdjustmentsViaReturnItem = ({ dispatch, commit }, returnItem) => {
  commit(types.API_REQUEST_START, 'fetchAllOrderAdjustmentsViaReturnItem')

  return new Promise((resolve, reject) => {
    returnItem
      .fetchAllOrderAdjustments()
      .then(response => {
        dispatch('orders/receiveAdjustmentsFormRelationship', response, { root: true })
        commit(types.API_REQUEST_SUCCESS, 'fetchAllOrderAdjustmentsViaReturnItem')
        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchAllOrderAdjustmentsViaReturnItem,
            ref: {
              dispatch,
              commit
            },
            params: returnItem
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const editAmountForReturnItem = ({ dispatch, commit }, returnItem) => {
  commit(types.API_REQUEST_START, 'editAmountForReturnItem')

  return new Promise((resolve, reject) => {
    returnItem
      .editAmount()
      .then(response => {
        commit(types.UPDATE_RETURN_AUTHORIZATION_ITEM_SUCCESS, response)
        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: editAmountForReturnItem,
            ref: {
              dispatch,
              commit
            },
            params: returnItem
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const acceptReturnItem = ({ dispatch, commit }, returnItem) => {
  commit(types.API_REQUEST_START, 'acceptReturnItem')

  return new Promise((resolve, reject) => {
    returnItem
      .accept()
      .then(response => {
        commit(types.UPDATE_RETURN_AUTHORIZATION_ITEM_SUCCESS, response)
        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: acceptReturnItem,
            ref: {
              dispatch,
              commit
            },
            params: returnItem
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const rejectReturnItem = ({ dispatch, commit }, returnItem) => {
  commit(types.API_REQUEST_START, 'rejectReturnItem')

  return new Promise((resolve, reject) => {
    returnItem
      .reject()
      .then(response => {
        commit(types.UPDATE_RETURN_AUTHORIZATION_ITEM_SUCCESS, response)
        commit(types.GET_RETURN_AUTHORIZATION_SUCCESS, response)
        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: rejectReturnItem,
            ref: {
              dispatch,
              commit
            },
            params: returnItem
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const giveReturnItemToCustomer = ({ dispatch, commit }, returnItem) => {
  commit(types.API_REQUEST_START, 'giveReturnItemToCustomer')

  return new Promise((resolve, reject) => {
    returnItem
      .giveToCustomer()
      .then(response => {
        commit(types.UPDATE_RETURN_AUTHORIZATION_ITEM_SUCCESS, response)
        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: giveReturnItemToCustomer,
            ref: {
              dispatch,
              commit
            },
            params: returnItem
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveReturnItem = ({ dispatch, commit }, returnItem) => {
  commit(types.API_REQUEST_START, 'receiveReturnItem')

  return new Promise((resolve, reject) => {
    returnItem
      .receive()
      .then(response => {
        commit(types.UPDATE_RETURN_AUTHORIZATION_ITEM_SUCCESS, response)
        resolve(response)
      })
      .catch(errors => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: receiveReturnItem,
            ref: {
              dispatch,
              commit
            },
            params: returnItem
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_RETURN_AUTHORIZATIONS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RETURN_AUTHORIZATION_SUCCESS, response)

    resolve(response)
  })
}
