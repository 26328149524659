import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'user_children',
  attributes: [
    'user_id',
    'id',
    'name',
    'nick_name',
    'birthday',
    'gender'
  ],
  editableAttributes: [
    'name',
    'nick_name',
    'birthday',
    'gender'
  ]
}

export default class UserChild extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  save() {
    let BasePath = `${this.apiBasePath({withResourceType: false})}/users/${this.user_id}/children`
    if (this.isNewRecord()) {
      return axios.post(`${BasePath}`, this.requestBody())
    }
    return axios.put(`${BasePath}/${this.id}`, this.requestBody())
  }

  destroy() {
    return axios.delete(`${this.apiBasePath({withResourceType: false})}/users/${this.user_id}/children/${this.id}`)
  }

  // extra methods or helpers here...
}
