export const FETCH_BRAND_BANNERS_SUCCESS = 'FETCH_BRAND_BANNERS_SUCCESS'
export const GET_RELATED_BRAND_BANNERS_SUCCESS = 'GET_RELATED_BRAND_BANNERS_SUCCESS'
export const GET_BRAND_BANNER_SUCCESS = 'GET_BRAND_BANNER_SUCCESS'
export const ADD_BRAND_BANNER_SUCCESS = 'ADD_BRAND_BANNER_SUCCESS'
export const UPDATE_BRAND_BANNER_SUCCESS = 'UPDATE_BRAND_BANNER_SUCCESS'
export const DELETE_BRAND_BANNER_SUCCESS = 'DELETE_BRAND_BANNER_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
