<template lang="pug">

.vc-product-search-box.tool
  input.input(v-model="currentSearchingData"
              :placeholder="messageLocaleText('exter_product_name_or_key_word')"
              type="text"
              @keydown.enter="searchOptionUpdated"
              @compositionend="composing=false"
              @compositionstart="composing=true")

</template>

<script>
import queryString from 'query-string'

export default {
  // components: {},
  // mixins: [],
  // props: {},

  data() {
    return {
      currentSearchingData: null,
      composing: false
    }
  },

  // computed: {},
  // created() {},
  mounted() {
    let currentQueryString = queryString.parse(window.location.search)
    this.currentSearchingData = currentQueryString['q[variants_with_master_sku_or_brand_name_or_name_cont]']
  },

  methods: {
    searchOptionUpdated() {
      if (!this.composing) {
        this.$store.dispatch('pixelOperation', [['track', 'Search']]).then(_ => {
          this.$emit('search-option-updated', encodeURI(this.currentSearchingData))
        })
      }
    }
  }
}
</script>
