<template lang="pug">

form.vc-order-address-form(@submit.prevent="")
  //- main content
  .section-label
    span {{ formSectionTitle }}
    a.form-function(v-if="addressType === 'billing' && isUserSignedIn"
                    @click="openCommonAddressList")
      .icon.is-small
        i.fa.fa-list-alt
      span.text {{ actionLocaleText('choose_form_common_addresses') }}

  .section-label(v-if="addressType === 'shipping'")
    b-checkbox(type="is-odd"
               v-model="form.is_same_as_billing_address"
               @input.native="flagFormAsEdited"
               v-if="isRequireAddressField") {{ attributeLocaleText('order', 'is_same_as_billing_address') }}
    span(v-else)
    a.form-function(v-if="!form.is_same_as_billing_address && isUserSignedIn"
                    @click="openCommonAddressList")
      .icon.is-small
        i.fa.fa-list-alt
      span.text {{ actionLocaleText('choose_form_common_addresses') }}

  template(v-if="isAddressFormFieldsShow")
    .form-section.has-sub-fields
      b-field(:label="recipientLabelName"
              :type="errors.errorClassAt(addressFieldFor('recipient'))"
              :message="errors.get(addressFieldFor('recipient'))")
        b-input(type="text"
                name="name"
                autocomplete="name"
                v-model="form[`${addressType}_address`].recipient"
                @input="errors.clear(addressFieldFor('recipient'))"
                @keyup.native="tryResetSelectedAddress")
      b-field(:label="attributeLocaleText('user/common_address', 'phone')"
              :type="errors.errorClassAt(addressFieldFor('phone'))"
              :message="errors.get(addressFieldFor('phone'))")
        b-input(type="tel"
                autocomplete="tel"
                v-model="form[`${addressType}_address`].phone"
                @input="errors.clear(addressFieldFor('phone'))"
                @keyup.native="tryResetSelectedAddress")
      template(v-if="addressType === 'billing'")
        b-field(:label="attributeLocaleText('user/common_address', 'company') + ` (${messageLocaleText('optional')})`"
                :type="errors.errorClassAt(addressFieldFor('company'))"
                :message="errors.get(addressFieldFor('company'))")
          b-input(type="text"
                  name="company"
                  autocomplete="organization"
                  v-model="form[`${addressType}_address`].company"
                  @input="errors.clear(addressFieldFor('company'))"
                  @keyup.native="tryResetSelectedAddress")
        b-field(:label="attributeLocaleText('user/common_address', 'ein') + ` (${messageLocaleText('optional')})`"
                :type="errors.errorClassAt(addressFieldFor('ein'))"
                :message="errors.get(addressFieldFor('ein'))")
          b-input(type="text"
                  v-model="form[`${addressType}_address`].ein"
                  autocomplete="ein"
                  @input="errors.clear(addressFieldFor('ein'))"
                  @keyup.native="tryResetSelectedAddress")

      template(v-if="isRequireAddressField")
        b-field(:label="attributeLocaleText('user/common_address', 'city')"
                :type="errors.errorClassAt(addressFieldFor('city'))"
                :message="errors.get(addressFieldFor('city'))")
          b-select(v-model="form[`${addressType}_address`].address.city"
                  autocomplete="locality"
                  @input="errors.clear(addressFieldFor('city'))")
            option(value=""
                  disabled
                  selected) {{ actionLocaleText('choose_one') }}
            option(v-for="(city, index) in cities"
                  :key="index"
                  :value="city") {{ city }}
        b-field(:label="attributeLocaleText('user/common_address', 'area')"
                :type="errors.errorClassAt(addressFieldFor('area'))"
                :message="errors.get(addressFieldFor('area'))")
          b-select(v-model="form[`${addressType}_address`].address.area"
                  autocomplete="region"
                  @input="errors.clear(addressFieldFor('area'))")
            option(value=""
                  disabled
                  selected) {{ actionLocaleText('choose_one') }}
            option(v-for="(area, index) in areas"
                  :key="index"
                  :value="area") {{ area }} ({{ getZipCode(area) }})

        b-field.is-full(:label="attributeLocaleText('user/common_address', 'street_address')"
                        :type="errors.errorClassAt(addressFieldFor('street_address'))"
                        :message="errors.get(addressFieldFor('street_address'))")
          b-input(type="text"
                  v-model="form[`${addressType}_address`].address.street_address"
                  autocomplete="street-address"
                  @input="errors.clear(addressFieldFor('street_address'))"
                  @keyup.native="tryResetSelectedAddress")

      .common-address-option(v-if="isSaveAsCommonAddressOptionShow")
        b-checkbox(type="is-odd"
                  v-model="form[`save_${addressType}_address_as_common`]") {{ actionLocaleText('save_as_common_address') }}

</template>

<script>
import isEqual from 'lodash.isequal'
import TaiwanZipCodes from '../../../../shared/services/taiwan_zip_codes.js'
import CommonAddressList from '../common_address/list.vue'

export default {
  components: {
    CommonAddressList
  },
  // mixins: [],
  props: {
    form: {
      type: Object,
      required: true
    },

    addressType: {
      type: String,
      required: true,
      validator: value => {
        return ['shipping', 'billing'].includes(value)
      }
    },

    shippingMethod: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      zipCodes: TaiwanZipCodes,
      selectedAddress: undefined,
      isFormEdited: false
    }
  },

  computed: {
    recipientLabelName() {
      return this.attributeLocaleText(
        'user/common_address',
        this.addressType === 'billing' ? 'buyer_name' : 'recipient'
      )
    },

    isRequireAddressField() {
      if (this.addressType === 'billing') return true

      return !this.shippingMethod.isStoreLogistic() && !this.shippingMethod.isCvsLogistic()
    },

    shippingType() {
      return this.shippingMethod.shipment_type_detail.shipping_type
    },

    formSectionTitle() {
      if (this.addressType === 'shipping' && !this.shippingMethod.isHomeLogistic())
        return this.attributeLocaleText('order', 'pick_up_info')

      return this.attributeLocaleText('order', `${this.addressType}_address`)
    },

    cities() {
      return Object.keys(TaiwanZipCodes)
    },

    areas() {
      if (
        this.form[`${this.addressType}_address`].address.city &&
        TaiwanZipCodes[this.form[`${this.addressType}_address`].address.city]
      )
        return Object.keys(TaiwanZipCodes[this.form[`${this.addressType}_address`].address.city])
    },

    errors() {
      return this.$store.getters['users/errors']
    },

    isSaveAsCommonAddressOptionShow() {
      return this.isFormEdited && !this.selectedAddress && this.isUserSignedIn
    },

    isAddressFormFieldsShow() {
      return this.addressType === 'billing' || !this.form.is_same_as_billing_address
    },

    extraDataForCommonAddress() {
      return this.addressType === 'billing' ? 'billing' : this.shippingType
    }
  },

  watch: {
    selectedAddress: {
      handler(newAddress, oldAddress) {
        if (!newAddress) return

        this.flagFormAsEdited()
        this.form[`${this.addressType}_address`].recipient = newAddress.recipient
        this.form[`${this.addressType}_address`].phone = newAddress.phone
        this.form[`${this.addressType}_address`].company = newAddress.company
        this.form[`${this.addressType}_address`].ein = newAddress.ein
        this.form[`${this.addressType}_address`].address = newAddress.address
      }
    },

    'form.shipping_address.address.city': {
      handler(newCity) {
        if (TaiwanZipCodes[newCity][this.form.shipping_address.address.area]) return

        this.form.shipping_address.address.area = ''
      }
    },

    'form.billing_address.address.city': {
      handler(newCity) {
        if (TaiwanZipCodes[newCity][this.form.billing_address.address.area]) return

        this.form.billing_address.address.area = ''
      }
    },

    'form.shipping_address.address.area': {
      handler(newArea) {
        if (newArea)
          this.form.shipping_address.address.zip =
            TaiwanZipCodes[this.form.shipping_address.address.city][this.form.shipping_address.address.area]
      }
    },

    'form.billing_address.address.area': {
      handler(newArea) {
        if (newArea)
          this.form.billing_address.address.zip =
            TaiwanZipCodes[this.form.billing_address.address.city][this.form.billing_address.address.area]
      }
    }
  },
  // created() {},
  mounted() {
    this.form[`${this.addressType}_address`].address.extra_data = this.extraDataForCommonAddress

    if (this.addressType === 'shipping' && !isEqual(this.form.shipping_address, this.form.billing_address))
      this.form.is_same_as_billing_address = false
  },

  methods: {
    openCommonAddressList() {
      this.$buefy.modal.open({
        parent: this,
        component: CommonAddressList,
        props: {
          selectedAddress: this.selectedAddress,
          logisticType: this.extraDataForCommonAddress
        },
        events: {
          'update:selectedAddress': address => (this.selectedAddress = address)
        }
      })
    },

    tryResetSelectedAddress() {
      this.selectedAddress = undefined
      this.flagFormAsEdited()
    },

    getZipCode(area) {
      return TaiwanZipCodes[this.form[`${this.addressType}_address`].address.city][area]
    },

    flagFormAsEdited() {
      this.isFormEdited = true
      this.$emit('form-edited')
    },

    addressFieldFor(field) {
      return this.addressType === 'shipping' ? field : `b_${field}`
    }
  }
}
</script>
