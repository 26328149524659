import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'user_common_addresses',
  attributes: ['id', 'recipient', 'phone', 'company', 'ein', 'address'],
  editableAttributes: ['recipient', 'phone', 'company', 'ein', 'address']
}

export default class UserCommonAddress extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
  fullAddress() {
    return `${this.address.zip} ${this.address.city} ${this.address.area} ${this.address.street_address}`
  }

  storeFullAddress() {
    return `${this.address.store_info.address.zip} ${this.address.store_info.address.city} ${this.address.store_info.address.area} ${this.address.store_info.address.street_address}`
  }

  hasCompanyInfo() {
    return this.company && this.ein
  }

  isCvsStoreInfo() {
    return ['fami_b2c', 'unimart_b2c', 'hilife_b2c', 'fami_c2c', 'unimart_c2c', 'hilife_c2c'].includes(
      this.address.extra_data
    )
  }

  isStoreInfo() {
    return this.address.extra_data === 'store'
  }

  isNormalAddress() {
    return !this.isCvsStoreInfo() && !this.isStoreInfo()
  }
}
