import RmaApplyWizard from '../../components/return_authorization/apply-wizard.vue'

export default {
  name: 'user-order-apply-rma-container',

  components: {
    RmaApplyWizard
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      orderToken: undefined
    }
  },

  computed: {
    order() {
      return this.$store.getters['orders/find'](this.orderToken)
    }
  },

  // created() {},

  mounted() {
    this.orderToken = this.$el.dataset.order
    this.$store.dispatch('users/getOrder', this.orderToken)
  }

  // methods: {}
}
