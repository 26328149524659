<template lang="pug">

.vc-order-store-selector
  .section-label
    span 取貨門市

  b-field(:type="errors.errorClassAt('store_info')"
          :message="errors.get('store_info')")
  .info-section
    .store-info(v-for="(storeInfo, index) in storeInfos"
            :ket="index")
      b-radio(type="is-odd"
              v-model="form.shipping_address.address.store_info"
              :native-value="storeInfo"
              @input="errors.clear('store_info')")
        .store-name.cell
          | {{ storeInfo.name }}
        .store-address.cell
          | {{ fullAddress(storeInfo.address)}}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    shippingMethod: {
      type: Object,
      required: true
    },

    form: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    storeInfos() {
      return this.shippingMethod.shipment_type_detail.store_infos
    },

    extraData() {
      return this.shippingMethod.shipment_type_detail.shipping_type
    }
  },

  watch: {
    'form.shipping_address.address.store_info': {
      handler(newAddress) {
        this.form.shipping_address.address.extra_data = this.extraData
        this.flagFormAsEdited()
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    fullAddress(address) {
      return `${address.zip}${address.city}${address.area}${address.street_address}`
    },

    flagFormAsEdited() {
      this.isFormEdited = true
      this.$emit('form-edited')
    }
  }
}
</script>
