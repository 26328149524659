import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'distribution_bases',
  attributes: [
    'id',
    'title',
    'address',
    'brands',
    'phone',
    'website',
    'note',
    'business_time',
    'excluded_products',
    'location_name',
    'location_id',
    'brands_count',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'title',
    'address',
    'phone',
    'website',
    'business_time',
    'location_id',
    'note'
  ]
}

export default class DistributionBase extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static all(options = {}) {
    let queryString = FetchingDataOptionsService.call(options)

    if (options.brand) queryString += `&brand=${options.brand}`
    if (options.location) queryString += `&location=${options.location}`

    return axios.get(`${new this().apiBasePath()}?${queryString}`)
  }

  fetchBrands(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/brands?${FetchingDataOptionsService.call(options)}`)
  }

  addBrandShip(brand) {
    const requestBody = {
      data: {
        type: 'distribution_base_brand_ships',
        attributes: {
          brand_id: brand.id
        }
      }
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/brand_ships`, requestBody)
  }
  // extra methods or helpers here...
  businessTimeTemplate() {
    return {
      start_weekday: 1,
      end_weekday: 5,
      start_time: '08:00',
      end_time: '17:00'
    }
  }
}
