import Cookies from 'js-cookie'

const COLLECTION_KEY = 'collection/collected-products'
const COLLECTION_LATEST_UPDATED_AT_KEY = 'collection/latest-updated-at'
const RESET_FLAG = 'reset_collections'

/**
 * 用來處理「收藏商品」的 Service object
 *
 * @export
 * @class ProductCollectService
 */
export default class ProductCollectService {
  /**
   * constructor
   *
   * @param {String} userType Vuex `currentUser` state 中的 type，用以辨別使用者是否已登入
   * @param {Array} currentCollections 目前已收藏的商品 IDs，可從 getter `userCollections/currentCollections` 取得
   * @param {Function} localForage 從 vue instance 傳入 _vm.$localforage
   * @param {Function} vuexStore 從 vue instance 傳入 _vm.$store
   */
  constructor(userType, currentCollections, localForage, vuexStore) {
    this.userType = userType
    this.currentCollections = currentCollections
    this.localForage = localForage
    this.vuexStore = vuexStore
  }

  /**
   * 初始化收藏商品清單，將 indexedDB 的儲存內容送到 Vuex store。
   * 若是使用者為登入會員，會把 server 端較新的資料傳送回 indexedDB。
   * 另外，使用者登出時 server 會在 cookies 中設定 RESET_FLAG，這會在初始化時清除本地端的收藏
   *
   * @returns
   * @memberof ProductCollectService
   */
  initialize() {
    if (this.userType === 'Guest') {
      return this._initializeForGuest()
    } else {
      return this._initializeForMember()
    }
  }

  /**
   * 收藏商品
   * Guest 僅會將資料放在 indexedDB
   * 登入會員會同時把資料透過 `product/collect` action 同步至 server
   *
   * @param {Object} product Product model 的 instance。
   * @returns
   * @memberof ProductCollectService
   */
  collect(product) {
    this.currentCollections.push(product.id)

    if (this.userType === 'Guest') {
      return this.localForage
        .setItem(COLLECTION_KEY, this.currentCollections)
        .then(value => {
          return this.vuexStore.dispatch('userCollections/storeCollectionsFromLocal', value)
        })
        .then(() => {
          return this.vuexStore.dispatch('userCollections/setLatestUpdatedAtFromLocal', Math.floor(Date.now() / 1000))
        })
    } else {
      return this.localForage.setItem(COLLECTION_KEY, this.currentCollections).then(() => {
        return this.vuexStore.dispatch('products/collect', product)
      })
    }
  }

  /**
   * 移除收藏
   * Guest 僅會將資料放在 indexedDB
   * 登入會員會同時把資料透過 `product/collect` action 同步至 server
   *
   * @param {Object} product Product model 的 instance。
   * @returns
   * @memberof ProductCollectService
   */
  uncollect(product) {
    const indexOfProduct = this.currentCollections.indexOf(product.id)
    this.currentCollections.splice(indexOfProduct, 1)

    if (this.userType === 'Guest') {
      return this.localForage
        .setItem(COLLECTION_KEY, this.currentCollections)
        .then(value => {
          return this.vuexStore.dispatch('userCollections/storeCollectionsFromLocal', value)
        })
        .then(() => {
          return this.vuexStore.dispatch('userCollections/setLatestUpdatedAtFromLocal', Math.floor(Date.now() / 1000))
        })
    } else {
      return this.localForage.setItem(COLLECTION_KEY, this.currentCollections).then(() => {
        return this.vuexStore.dispatch('products/uncollect', product)
      })
    }
  }

  static collectionKey() {
    return COLLECTION_KEY
  }

  static collectionLatestUpdatedAtKey() {
    return COLLECTION_LATEST_UPDATED_AT_KEY
  }

  // ======================================================================== //

  _initializeForGuest() {
    return this._tryResetCollectionsAtLocal()
      .then(() => {
        return this.localForage.getItem(COLLECTION_KEY)
      })
      .then(collections => {
        if (collections) {
          this.vuexStore
            .dispatch('userCollections/storeCollectionsFromLocal', collections)
            .then(() => {
              return this.localForage.getItem(COLLECTION_LATEST_UPDATED_AT_KEY)
            })
            .then(latestUpdatedAt => {
              return this.vuexStore.dispatch('userCollections/setLatestUpdatedAtFromLocal', latestUpdatedAt)
            })
        }
      })
  }

  _initializeForMember() {
    return this.localForage.getItem(COLLECTION_KEY).then(collections => {
      if (collections) {
        return this.localForage
          .getItem(COLLECTION_LATEST_UPDATED_AT_KEY)
          .then(latestUpdatedAt => {
            return this.vuexStore.dispatch('users/getCollections', latestUpdatedAt)
          })
          .catch(errors => {
            if (errors.response.status === 304)
              this.vuexStore.dispatch('userCollections/storeCollectionsFromLocal', collections)
          })
      } else {
        return this.vuexStore.dispatch('users/getCollections').catch(errors => {
          if (errors.response.status === 304) return
        })
      }
    })
  }

  _tryResetCollectionsAtLocal() {
    if (Cookies.get(RESET_FLAG) === '1') {
      return this.localForage.setItem(COLLECTION_KEY, []).then(() => {
        Cookies.remove(RESET_FLAG)
      })
    } else {
      return Promise.resolve(true)
    }
  }
}
