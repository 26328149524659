<template lang="pug">

.vc-common-mobile-category-list.menu-wrapper
  b-loading(:active="isLoading"
            :is-full-page="false")
  transition(enter-active-class="animated slideInLeft"
              leave-active-class="animated slideOutLeft")
    ul.mobile-menu-items(v-if="!isSubCategoriesMenuShow")
      li.mobile-menu-item(v-for="topLevelCategory in topLevelCategories"
                          :key="topLevelCategory.id")
        a.link(href="#"
              @click.prevent="topLevelCategoryClickedHandler(topLevelCategory)")
          span.text {{ topLevelCategory.name }}
          .icon(v-if="!topLevelCategory.is_leaf()")
            i.fa.fa-chevron-right
  transition(enter-active-class="animated slideInRight"
              leave-active-class="animated slideOutRight")
    ul.mobile-menu-items(v-if="isSubCategoriesMenuShow")
      li.mobile-menu-item
        a.link.selected-top-level-category(:href="`/${categoryType}s?category_id=${selectedCategory.id}`")
          span.text {{ selectedCategory.name }}
      li.mobile-menu-item(v-for="subCategory in subCategories"
                          :key="subCategory.id")
        a.link(:href="`/${categoryType}s?category_id=${subCategory.id}`")
          span.text {{ subCategory.name }}
</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    depth: {
      type: Number,
      required: false,
      default: () => {
        return 2
      }
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    categoryType() {
      return this.mobileMenuStates.activatedCategoryType
    },

    isSubCategoriesMenuShow() {
      return (
        this.mobileMenuStates['isCategoriesSelectorActive'] &&
        this.mobileMenuStates['selectedTopLevelCategoryId'] !== null
      )
    },

    childOfRootCategory() {
      return this.$store.getters['categorySystems/categoriesByUsage'](this.categoryType)[0]
    },

    topLevelCategories() {
      let childOfRootCategory = this.childOfRootCategory

      if (!childOfRootCategory) return []

      return this.$store.getters['categorySystems/allCategories']({
        depth: this.depth
      }).filter(category => childOfRootCategory.id === String(category.parent_id))
    },

    selectedCategory() {
      return this.$store.getters['categorySystems/findCategory'](this.mobileMenuStates['selectedTopLevelCategoryId'])
    },

    subCategories() {
      return this.$store.getters['categorySystems/allCategories']().filter(
        category => String(category.parent_id) === this.selectedCategory.id
      )
    },

    isLoading() {
      return this.$store.getters['categorySystems/isLoading']
    }
  },
  // created() {},
  mounted() {
    if (this.topLevelCategories.length > 0) return

    this.$store.dispatch(`categorySystems/fetchCategoriesByType`, {
      type: this.categoryType,
      sort: 'created_at',
      noReplace: true
    })
  },

  methods: {
    topLevelCategoryClickedHandler(topLevelCategory) {
      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(this.mobileMenuStates, { selectedTopLevelCategoryId: topLevelCategory.id })
      )
    }
  }
}
</script>
