<template lang="pug">

.vc-product-category-sub-category-unit(:class="{ 'is-active': isSelected }"
                                       @click="selectSubCategory")
  span.name {{ subCategoryInstance.name }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    subCategory: {
      type: Object,
      required: true
    },

    currentSubCategory: {
      type: Object,
      required: false
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    subCategoryInstance() {
      return this.$store.getters['productCategories/find'](this.subCategory.id)
    },

    isSelected() {
      return this.currentSubCategory && this.currentSubCategory.id === this.subCategoryInstance.id
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    selectSubCategory() {
      this.$emit('sub-category-selected', this.subCategoryInstance)
    }
  }
}
</script>
