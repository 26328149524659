export const FETCH_DISTRIBUTION_BASES_SUCCESS = 'FETCH_DISTRIBUTION_BASES_SUCCESS'
export const GET_RELATED_DISTRIBUTION_BASES_SUCCESS = 'GET_RELATED_DISTRIBUTION_BASES_SUCCESS'
export const GET_DISTRIBUTION_BASE_SUCCESS = 'GET_DISTRIBUTION_BASE_SUCCESS'
export const ADD_DISTRIBUTION_BASE_SUCCESS = 'ADD_DISTRIBUTION_BASE_SUCCESS'
export const UPDATE_DISTRIBUTION_BASE_SUCCESS = 'UPDATE_DISTRIBUTION_BASE_SUCCESS'
export const DELETE_DISTRIBUTION_BASE_SUCCESS = 'DELETE_DISTRIBUTION_BASE_SUCCESS'
export const FETCH_DISTRIBUTION_BASE_LOCATIONS_SUCCESS = 'FETCH_DISTRIBUTION_BASE_LOCATIONS_SUCCESS'
export const ADD_DISTRIBUTION_BASE_LOCATION_SUCCESS = 'ADD_DISTRIBUTION_BASE_LOCATION_SUCCESS'
export const UPDATE_DISTRIBUTION_BASE_LOCATION_SUCCESS = 'UPDATE_DISTRIBUTION_BASE_LOCATION_SUCCESS'
export const DELETE_DISTRIBUTION_BASE_LOCATION_SUCCESS = 'DELETE_DISTRIBUTION_BASE_LOCATION_SUCCESS'
export const FETCH_DISTRIBUTION_BASE_BRAND_SHIPS_SUCCESS = 'FETCH_DISTRIBUTION_BASE_BRAND_SHIPS_SUCCESS'
export const DELETE_DISTRIBUTION_BASE_BRAND_SHIP_SUCCESS = 'DELETE_DISTRIBUTION_BASE_BRAND_SHIP_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
