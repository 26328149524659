<template lang="pug">

.vc-product-on-sale-products(v-if="shouldShow")
  b-loading(:active.sync="isLoading"
            :is-full-page="false")

  product-card(v-for="(product, index) in onSaleProducts"
               :key="'onsale' + product.id"
               :product="product"
               :index="index"
               :list-location="listLocation")

</template>

<script>
import ProductCard from './card.vue'

export default {
  components: {
    ProductCard
  },
  // mixins: [],
  props: {
    productsCount: {
      type: Number,
      required: false,
      default: () => {
        return 8
      }
    }
  },

  data() {
    return {
      onSaleProductIds: null,
      isLoading: true,
      shouldShow: true
    }
  },

  computed: {
    onSaleProducts() {
      if (this.onSaleProductIds) {
        return this.$store.getters['products/all'].filter(product => this.onSaleProductIds.includes(product.id))
      } else {
        return []
      }
    },

    listLocation() {
      return 'On Sale Products'
    }
  },

  watch: {
    onSaleProductIds: {
      handler(newValue) {
        if (newValue.length === 0) this.shouldShow = false
      }
    }
  },

  created() {
    this.$store
      .dispatch('products/onSaleProducts', {
        pageNumber: 1,
        pageSize: this.productsCount,
        sort: '-updated_at'
      })
      .then(response => {
        this.onSaleProductIds = response.data.data.map(product => product.id)
        this.isLoading = false
      })
  }
  // mounted() {},
  // methods: {}
}
</script>
