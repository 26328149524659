<template lang="pug">

.vc-return-authorization-apply-wizard
  number-and-placed-date(:order="order")

  template(v-if="isFormReady")
    template(v-if="step === 1")
      shipment-detail(v-for="(shipment, index) in shipments"
                      :shipment="shipment"
                      :form="form"
                      :index="index"
                      :key="shipment.id")
      .odd-container.options
        button.button.is-odd(:disabled="this.form.items.length < 1"
                             @click="nextStep") {{ actionLocaleText('next_step') }}

    template(v-if="step === 2")
      rma-form(:form="form")
      .odd-container.options
        button.button.is-default(@click="prevStep") {{ actionLocaleText('prev_step') }}
        button.button.is-odd(:disabled="!this.form.reason_id"
                             @click="submit") {{ actionLocaleText('submit') }}

</template>

<script>
import Form from 'odd-form_object'
import ReturnAuthorization from '../../../../shared/resource_models/return_authorization.js'
import NumberAndPlacedDate from '../order/number-and-placed-date.vue'
import ShipmentDetail from '../order_shipment/detail.vue'
import RmaForm from './form.vue'

export default {
  components: {
    NumberAndPlacedDate,
    ShipmentDetail,
    RmaForm
  },
  // mixins: [],
  props: {
    order: {
      type: Object,
      required: true
    },

    returnAuthorization: {
      type: Object,
      required: false,
      default: () => {
        return new ReturnAuthorization()
      }
    }
  },

  data() {
    return {
      step: 1,
      form: new Form(this.returnAuthorization),
      isFormReady: false
    }
  },

  computed: {
    shipments() {
      return this.$store.getters['orderShipments/all']
    }
  },
  // created() {},
  mounted() {
    if (this.form.items === undefined) this.form.items = []
    this.isFormReady = true
  },

  methods: {
    nextStep() {
      this.step += 1
    },

    prevStep() {
      this.step -= 1
    },

    submit() {
      this.$store
        .dispatch('users/applyRma', {
          orderToken: this.order.id,
          returnAuthorization: this.form.sync()
        })
        .then(response => {
          Turbolinks.visit(`/user/return_authorizations/${response.data.data.attributes.number}?created=1`)
        })
    }
  }
}
</script>
