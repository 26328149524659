<template lang="pug">

.vc-brand-slideshow(v-if="hasBanner")
  .slideshow-wrapper
    swiper(:options="swiperOption")
      swiper-slide(v-for="banner in banners"
                    :key="banner.id")
        img.image(:src="banner.image.url")
      .swiper-pagination(slot="pagination")

</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    swiper,
    swiperSlide
  },
  // mixins: [],
  props: {
    brand: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      swiperOption: {
        effect: 'fade',
        grabCursor: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true
        },
        lazy: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },

  computed: {
    banners() {
      return this.$store.getters['brandBanners/all']
    },

    hasBanner() {
      return this.banners.length > 0
    }
  },

  created() {
    this.$store.dispatch('brands/fetchBanners', {
      model: this.brand,
      options: {
        sort: 'brand_banners.position'
      }
    })
  }
  // mounted() {},
  // methods: {}
}
</script>
