<template lang="pug">

.vc-return-authorization-detail-unit

  .content-wrapper
    h4.section-title {{ modelNameLocaleText('return_authorization/item') }}
    .item-unit(v-for="returnItem in returnItems"
              :key="returnItem.shipment_item_id")
      .group
        .group.option-and-thumb
          .product-thumb
            img.img(:src="findVariantThumb(returnItem.variant.id)")
        .group.name-and-unit-price
          .product-name
            .state
              span.tag(:class="returnItem.receptionStateType") {{ aasmStateLocaleText('return_authorization/item', 'reception_state', returnItem.reception_state) }}
            .name {{ findProductName(returnItem.variant.id) }}
          .unit-price(:class="{ 'is-canceled': returnItem.acceptance_state === 'rejected' }")
            label.label {{ attributeLocaleText('order/item', 'price') }}
            .price {{ returnItem.displayAmount() }}
      .group
        .quantity-info
          label.label {{ attributeLocaleText('order/shipment_item', 'quantity') }}
          .quantity {{ returnItem.return_quantity }}

    .extra-info
      .column
        h4.section-title {{ attributeLocaleText('return_authorization', 'reason_id') }}
        .reason
          span {{ returnAuthorization.reason }}
      .column
        h4.section-title {{ attributeLocaleText('return_authorization', 'note') }}
        .note(v-html="simpleFormat(returnAuthorization.note)")

    .return-total
      label.label {{ attributeLocaleText('return_authorization', 'total_amount') }}
      span.price {{ returnAuthorization.displayTotalAmount() }}

</template>

<script>
export default {
  // components: {},
  // mixins: [],
  props: {
    returnAuthorization: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    returnItems() {
      return this.$store.getters['returnAuthorizations/allItems'].filter(
        item => item.return_authorization.id === this.returnAuthorization.id
      )
    },

    variants() {
      return this.$store.getters['productVariants/all']
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    findVariantThumb(id) {
      return this.variants.find(variant => variant.id === id).cover.thumb.url
    },

    findProductName(id) {
      return this.variants.find(variant => variant.id === id).product_name
    }
  }
}
</script>
