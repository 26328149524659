import SideMenuUnit from '../../components/dashboard/profile/side-menu-unit.vue'
import UserInfoCard from '../../components/dashboard/user-info-card.vue'
import PasswordForm from '../../components/dashboard/password-form.vue'
import UserProfileForm from '../../components/dashboard/user-profile-form.vue'

const MENU_TO_COMPONENTS_MAP = {
  account_security: 'password-form',
  user_profile_management: 'user-profile-form'
}
const MOBILE_MODE_BREAK_POINT = 769

export default {
  name: 'user-settings-container',

  components: {
    SideMenuUnit,
    UserInfoCard,
    PasswordForm,
    UserProfileForm
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      avaliableMenu: [
        'user_profile_management',
        'account_security'
      ],
      selectedMenu: null
    }
  },

  computed: {
    sectionState() {
      return {
        'is-mobile-mode': this.isMobile,
        'is-menu-selectable': !this.isMenuSelected,
        'is-menu-selected': this.isMenuSelected
      }
    },

    currentComponent() {
      return MENU_TO_COMPONENTS_MAP[this.selectedMenu]
    },

    isMobile() {
      return this.$store.getters['windowWidth'] < MOBILE_MODE_BREAK_POINT
    },

    isMenuSelected() {
      return this.selectedMenu !== null
    },

    currentUserData() {
      return this.$store.getters['users/find'](this.currentUser.id)
    }
  },

  watch: {
    isMobile(newValue) {
      if (newValue) return
      if (this.selectedMenu === null) return this.initMenu()
    }
  },

  // created() {},

  mounted() {
    if (this.isMobile) return
    this.initMenu()
  },

  methods: {
    initMenu() {
      this.selectedMenu = this.avaliableMenu[0]
    },

    menuSelectedHandler(selectedMenu) {
      this.selectedMenu = selectedMenu
    }
  }
}
