import CategoryMenu from '../../components/category/sidebar-category-menu.vue'
import SalesEventList from '../../components/sales_event/list.vue'
import SlideshowContainer from '../../components/common/slideshow-container.vue'

export default {
  name: 'sales-events-index-page-container',

  components: {
    CategoryMenu,
    SalesEventList,
    SlideshowContainer
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      selectedCategory: undefined,
      categoryMenuIsReady: false
    }
  }

  // computed: {},

  // created() {},

  // mounted() {},

  // methods: {}
}
