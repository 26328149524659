import debounce from 'lodash.debounce'
import MobileMenu from '../components/common/mobile-menu.vue'
import MemberOptionButton from '../components/common/member-options-button.vue'
import MemberCartButton from '../components/common/member-cart-button.vue'
import SearchButton from '../components/common/search-button.vue'
import logoH from '../../images/logo.svg'
import logoMobileH from '../../images/logo.svg'

const MOBILE_MENU_BREAK_POINT = 769

export default {
  name: 'site-navbar-container',

  components: {
    MobileMenu,
    MemberOptionButton,
    MemberCartButton,
    SearchButton
  },

  // mixins: [],

  // props: {},

  data() {
    return {
      prevScrollPosition: 0,
      debounceShowBoxShadowOnScrollDown: null,
      debounceAutoHideNavbarAtScrollDown: null,
      isSearchFormActive: false
    }
  },

  computed: {
    isMobile() {
      // workaround for safari can't get window width from vuex at first time loading
      return (this.$store.getters['windowWidth'] || window.innerWidth) < MOBILE_MENU_BREAK_POINT
    },

    mobileMenuStates() {
      return this.$store.getters['mobileMenuStates']
    },

    logo() {
      return this.isMobile ? logoMobileH : logoH
    },

    menuTriggerClass() {
      return {
        'is-active': this.mobileMenuStates['isMobileMenuActive'],
        'is-child-level': this.isChildLevel
      }
    },

    isChildLevel() {
      let mobileMenuStates = this.mobileMenuStates

      return mobileMenuStates['isSkippingRootMenuToClose']
        ? !!mobileMenuStates['selectedTopLevelCategoryId']
        : mobileMenuStates['isCategoriesSelectorActive']
    },

    searchFormClass() {
      return {
        'is-active': this.isSearchFormActive
      }
    }
  },

  // created() {},

  mounted() {
    this.debounceShowBoxShadowOnScrollDown = debounce(this.showBoxShadowOnScrollDown, 100, {
      leading: true,
      trailing: true
    })
    this.debounceAutoHideNavbarAtScrollDown = debounce(this.autoHideNavbarAtScrollDown, 100, {
      leading: true,
      trailing: true
    })

    window.addEventListener('scroll', this.debounceShowBoxShadowOnScrollDown)
    window.addEventListener('scroll', this.debounceAutoHideNavbarAtScrollDown)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.debounceShowBoxShadowOnScrollDown)
    window.removeEventListener('scroll', this.debounceAutoHideNavbarAtScrollDown)
  },

  methods: {
    mobileMenuTriggerHandler() {
      if (this.isChildLevel) return this.prevButtonClickedHandler()

      let mobileMenuStates = this.mobileMenuStates

      // 關閉 或從 nav 打開 menu 時，
      // isSkippingRootMenuToClose 應為 false, 防止從 NavBar 進到 category list 時返回卻直接關掉 Menu;
      // isCategoriesSelectorActive 應為 false, 讓 menuTriggerClass 保持在正確的狀態
      this.$store.dispatch(
        'toggleMobileMenu',
        Object.assign(mobileMenuStates, {
          isMobileMenuActive: !mobileMenuStates['isMobileMenuActive'],
          isCategoriesSelectorActive: false,
          isSkippingRootMenuToClose: false
        })
      )
    },

    prevButtonClickedHandler() {
      let menuStates = Object.assign(this.mobileMenuStates)

      this.mobileMenuStates['selectedTopLevelCategoryId']
        ? (menuStates['selectedTopLevelCategoryId'] = null)
        : (menuStates['isCategoriesSelectorActive'] = false)

      this.$nextTick(() => {
        this.$store.dispatch('toggleMobileMenu', menuStates)
      })
    },

    toggleSearchForm() {
      this.isSearchFormActive = !this.isSearchFormActive
    },

    autoHideNavbarAtScrollDown() {
      const currentScrollPosition = window.pageYOffset
      const navBar = this.$el

      if (currentScrollPosition > this.prevScrollPosition && currentScrollPosition > navBar.scrollHeight) {
        if (this.mobileMenuStates['isMobileMenuActive']) return // dont hide menu if mobile menu activated

        navBar.classList.add('hidden')
      } else {
        navBar.classList.remove('hidden')
      }
      this.prevScrollPosition = currentScrollPosition
    },

    showBoxShadowOnScrollDown() {
      if (window.pageYOffset > 0) {
        document.querySelector('.site-navbar').classList.add('has-shadow')
      } else {
        document.querySelector('.site-navbar').classList.remove('has-shadow')
      }
    }
  }
}
