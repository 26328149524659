import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'orders',
  attributes: [
    'adjustment_total',
    'approved_at',
    'approver',
    'billing_address',
    'canceled_at',
    'completed_at',
    'created_at',
    'email',
    'id',
    'is_multi_addresses',
    'items',
    'item_total',
    'notes',
    'number',
    'order_state',
    'payment_state',
    'payment_method_name',
    'payment_total',
    'payment_paid_at',
    'payment',
    'promotion_total',
    'shipment_state',
    'shipment_total',
    'shipments',
    'shipping_address',
    'shipping_method',
    'tax_total',
    'total',
    'updated_at',
    'user',
    'is_agree_to_join_membership',
    'is_placed_by_guest',
    'comments',
    'has_new_comment',
    'has_new_reply',
    'buyer_name',
    'buyer_phone',
    'shipment_state_info',
    'coupons',
    'shipment_fee_total',
    'gift_items'
  ],
  editableAttributes: [
    'email',
    'shipping_address',
    'billing_address',
    'is_same_as_billing_address',
    'notes',
    'is_agree_with_term_of_service',
    'is_agree_to_join_membership',
    'is_agree_to_receive_news_letters',
    'save_billing_address_as_common',
    'save_shipping_address_as_common',
    'payment_method_id',
    'addresses',
    'buyer_name',
    'buyer_phone'
  ]
}

export default class Order extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static detailInfo(token) {
    return axios.get(`${new this().apiBasePath()}/${token}/detail_info`)
  }

  static approveInBatches(orderIds = []) {
    if (orderIds.length <= 0) return

    const requestBody = {
      data: {
        type: 'approve_order_in_batches',
        attributes: {
          order_ids: orderIds
        }
      }
    }

    return axios.put(`${new this().apiBasePath()}/approve_in_batches`, requestBody)
  }

  makePayment() {
    return axios.post(`${this.apiBasePath()}/${this.id}/make_payment`)
  }

  fetchComments(options = {}) {
    return axios.get(`${this.apiBasePath()}/${this.id}/comments?${FetchingDataOptionsService.call(options)}`)
  }

  addComment(content) {
    const requestBody = {
      data: {
        type: 'add_comment',
        attributes: {
          content
        }
      }
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/comments`, requestBody)
  }

  cancel() {
    return axios.put(`${this.apiBasePath()}/${this.id}/cancel`)
  }

  approve() {
    return axios.put(`${this.apiBasePath()}/${this.id}/approve`)
  }

  guestUrl() {
    return axios.get(`${this.apiBasePath()}/${this.id}/guest_url`)
  }

  transferTo(userId, reason) {
    const requestBody = {
      data: {
        type: 'order_transfer',
        attributes: {
          user_id: userId,
          reason
        }
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/transfer`, requestBody)
  }

  editContactInfo() {
    return axios.put(`${this.apiBasePath()}/${this.id}/contact_info`, this.requestBody())
  }

  markAsRead() {
    return axios.put(`${this.apiBasePath()}/${this.id}/mark_as_read`)
  }

  // extra methods or helpers here...

  orderStateType() {
    return {
      'is-default': ['cart', 'item_confirmed', 'confirmed'].includes(this.order_state),
      'is-success': this.order_state === 'completed',
      'is-danger': this.order_state === 'canceled'
    }
  }

  paymentStateType() {
    return {
      'is-default': ['checkout', 'balance_due'].includes(this.payment_state),
      'is-success': this.payment_state === 'paid',
      'is-warning': ['credit_owed', 'waiting_refund'].includes(this.payment_state),
      'is-dark': this.payment_state === 'void',
      'is-error': this.payment_state === 'payment_failed'
    }
  }

  shipmentStateType() {
    return {
      'is-default': this.shipment_state === 'shipment_pending',
      'is-info': this.shipment_state === 'shipment_tallying',
      'is-success': this.shipment_state === 'shipped',
      'is-warning': ['shipment_ready', 'partial_shipped'].includes(this.shipment_state),
      'is-error': this.shipment_state === 'backorder'
    }
  }

  isPlacedByUser() {
    return !this.is_placed_by_guest
  }

  /**
   * 訂單是否可確認。
   * （已完成且已付款，並且尚未出貨的訂單可進行審核確認）
   *
   * @returns {Boolean}
   */
  canApprove() {
    if (this.order_state !== 'completed' || this.approved_at) return false

    return ['shipment_pending', 'shipment_ready', 'shipment_tallying'].includes(this.shipment_state)
  }

  /**
   * 訂單是否可取消。 尚未出貨前都可以取消訂單。（給 OrderService::Canceler 處理）
   *
   * @returns {Boolean}
   */
  canCancelBy(user) {
    if (this.order_state !== 'completed') return false // 訂單尚未完成
    if (!user.isAdmin() && this.approved_at) return false // 訂單以審核，非管理員不能取消

    return !['shipped', 'partial_shipped', 'shipment_tallying'].includes(this.shipment_state)
  }

  canApplyForRma() {
    return ['shipped', 'partial_shipped', 'shipment_tallying'].includes(this.shipment_state)
  }

  canMakePayment() {
    return this.order_state === 'completed' && ['balance_due', 'payment_failed'].includes(this.payment_state)
  }

  expressMap(options = {}) {
    let optionsQueryString = Object.keys(options)
      .map(key => `${key}=${options[key]}`)
      .join('&')

    return axios.post(`${this.apiBasePath()}/${this.id}/express_map?${optionsQueryString}`)
  }

  displayPrice(
    priceType = 'total',
    options = {
      toLocaleString: true,
      customText: '-'
    }
  ) {
    let result = this[priceType] / 100

    if (!options.toLocaleString) return result

    if (result === 0) {
      result = options.customText
    } else {
      result = result.toLocaleString()
    }

    return result
  }

  fullAddress(type = 'billing_addresses') {
    return `${this[type].address.zip} ${this[type].address.city} ${this[type].address.area} ${this[type].address.street_address}`
  }

  formTemplate() {
    const addressTemplate = () => {
      return {
        recipient: '',
        phone: '',
        company: '',
        ein: '',
        address: {
          city: '',
          area: '',
          street_address: '',
          zip: '',
          cvs_store_info: {},
          store_info: {}
        }
      }
    }

    return {
      shipping_address: this.shipping_address || addressTemplate(),
      is_same_as_billing_address: true,
      billing_address: this.billing_address || addressTemplate(),
      is_agree_with_term_of_service: true,
      is_agree_to_receive_news_letters: true,
      save_shipping_address_as_common: false,
      save_billing_address_as_common: false
    }
  }
}
