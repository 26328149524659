import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'order_refunds',
  attributes: [
    'id',
    'amount',
    'state',
    'bank_code',
    'account',
    'recipient',
    'order',
    'payment',
    'reimbursement',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['bank_code', 'account', 'recipient']
}

export default class OrderRefund extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  confirmRefund() {
    return axios.put(`${this.apiBasePath()}/${this.id}/confirm_refund`)
  }

  // extra methods or helpers here...
  get stateType() {
    return {
      'is-warning': this.state === 'pending',
      'is-danger': this.state === 'failed',
      'is-success': this.state === 'done'
    }
  }

  get hasRefundInfo() {
    return !!this.bank_code && !!this.account && !!this.recipient
  }

  displayAmount(
    options = {
      toLocaleString: true
    }
  ) {
    if (options.toLocaleString) {
      return (this.amount / 100).toLocaleString()
    } else {
      return this.amount / 100
    }
  }
}
