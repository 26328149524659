export default {
  // components: {},

  props: {
    order: {
      type: Object,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isProcessing: false,
      isButtonBuilt: false
    }
  },

  computed: {
    paymentParams() {
      return this.$store.getters['orders/paymentParams']
    },

    gatewayApiBase() {
      return this.$store.getters['orders/gatewayApiBase']
    }
  },

  methods: {
    makePayment() {
      this.isProcessing = true

      this._ensurePaymentButtonWithCorrectParams()
        .then(() => {
          this.$refs['payment-form'].submit()
          setTimeout(() => {
            this.isProcessing = false
          }, 5000)
        })
        .catch(errors => {
          this._errorHandler(errors)
          this.isProcessing = false
        })
    },

    _ensurePaymentButtonWithCorrectParams() {
      if (this.isButtonBuilt) return Promise.resolve(true)

      return this.cartService.makePayment(this.order).then(_ => {
        return this._buildPaymentButton()
      })
    },

    _buildPaymentButton() {
      const paymentForm = this.$refs['payment-form']
      const paramKeys = Object.keys(this.paymentParams)

      paramKeys.map(key => {
        let input = document.createElement('input')

        input.setAttribute('type', 'hidden')
        input.setAttribute('name', key)
        input.setAttribute('value', this.paymentParams[key])
        paymentForm.appendChild(input)
      })
      this.isButtonBuilt = true
    },

    _errorHandler(errors) {
      if (errors.response.status === 422 && errors.response.data.code === 'stock_not_enough_failure') {
        this._stockNotEnoughtFailureHandler(errors)
      } else {
        this.$emit('back-to-first-step')
      }
    },

    _stockNotEnoughtFailureHandler(errors) {
      const response = errors.response.data

      this.$buefy.snackbar.open({
        message: response.message,
        type: 'is-info',
        position: 'is-bottom-left',
        actionText: this.actionLocaleText('adjust_order_items'),
        indefinite: true,
        queue: false,
        onAction: () => {
          this.$emit('back-to-first-step')
        }
      })
    }
  }
}
