<template lang="pug">

.vc-order-payment-refund-info-form
  h3.sub-title 您的帳戶資訊
  .message
    p 請您提供您的銀行帳戶資訊，我們會盡快將退款款項匯至您的戶頭。
    b-field(:label="attributeLocaleText('order/payment', 'refund_bank_code')"
            :type="errors.errorClassAt('refund_bank_code')"
            :message="errors.get('refund_bank_code')")
      b-input(type="text"
              v-model="bankCode"
              @input="errors.clear('refund_bank_code')")
    b-field(:label="attributeLocaleText('order/payment', 'refund_account')"
            :type="errors.errorClassAt('refund_account')"
            :message="errors.get('refund_account')")
      b-input(type="text"
              v-model="account"
              @input="errors.clear('refund_account')")
    b-field(:label="attributeLocaleText('order/payment', 'refund_recipient')"
            :type="errors.errorClassAt('refund_recipient')"
            :message="errors.get('refund_recipient')")
      b-input(type="text"
              v-model="recipient"
              @input="errors.clear('refund_recipient')")

    .button.is-primary(@click="submitForm"
                       :class="{ 'is-loading': isLoading }") {{ actionLocaleText('submit') }}

</template>

<script>
// import Form from 'odd-form_object'
// import orderPayment from '../../../../shared/resource_models/order_payment.js'

export default {
  // components: {},
  // mixins: [],
  props: {
    jwt: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      bankCode: '',
      account: '',
      recipient: '',
      isDataUpdated: false
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['orderPayments/isLoading']
    },

    errors() {
      return this.$store.getters['orderPayments/errors']
    },

    requestBody() {
      return {
        data: {
          type: 'order_payments',
          attributes: {
            refund_bank_code: this.bankCode,
            refund_account: this.account,
            refund_recipient: this.recipient,
            jwt: this.jwt
          }
        }
      }
    }
  },
  // created() {},
  // mounted() {},
  methods: {
    submitForm() {
      this.$store
        .dispatch('orderPayments/updateRefundInfo', this.requestBody)
        .then(_ => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('refund_info_updated_successfully')
          ])
        })
        .then(_ => {
          setTimeout(() => {
            Turbolinks.visit('/')
          }, 3000)
        })
    }
  }
}
</script>
