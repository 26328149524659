import backendPaginateFilterSortAndSearchableMixin from '../../../../shared/vue_mixins/backend_paginate_filter_sort_and_searchable_mixin.js'
import ReturnAuthorizationUnit from '../../components/return_authorization/summary-unit.vue'
import OddPagination from '../../components/common/odd-pagination.vue'

export default {
  name: 'user-rma-list-container',

  components: {
    OddPagination,
    ReturnAuthorizationUnit
  },

  mixins: [backendPaginateFilterSortAndSearchableMixin],

  // props: {},

  data() {
    return {
      resourceType: 'returnAuthorizations',
      currentPage: 1,
      pageSize: 20,
      sortOrder: 'desc',
      sortField: 'created_at'
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['users/isLoading']
    },

    returnAuthorizations() {
      return this.$store.getters['returnAuthorizations/all']
    }
  },

  created() {
    this.fetchingInitialData()
  },

  // mounted() {},

  methods: {
    fetchData(options) {
      this.currentPage = options.pageNumber

      this.$store.dispatch(`users/fetchReturnAuthorizations`, options)
    }
  }
}
