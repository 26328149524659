export default {
  name: 'site-footer-container',

  components: {},

  // mixins: [],

  // props: {},

  data() {
    return {}
  },

  computed: {},

  created() {},

  methods: {}
}
