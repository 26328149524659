import flatten from 'lodash.flatten'

export default {
  // components: {},
  props: {
    salesEvent: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  computed: {
    _eventPriceCompareTarget() {
      console.log(
        'please implement _eventPriceCompareTarget computed property first. It must be an instance of Product or ProductVariant.'
      )
    },

    eventPrices() {
      return flatten(
        this.salesEvent.product_ships.map(product => {
          return product.event_prices.map(priceInfo => {
            return {
              id: priceInfo.id,
              sku: priceInfo.variant_sku,
              price: priceInfo.price,
              variant_id: priceInfo.variant_id
            }
          })
        })
      )
    },

    selectedEventPriceObject() {
      return this.eventPrices.find(priceInfo => priceInfo.sku === this._eventPriceCompareTarget.sku)
    },

    eventPrice() {
      if (this.selectedEventPriceObject) {
        return this.selectedEventPriceObject.price / 100
      } else {
        return this._eventPriceCompareTarget.displayPrice('consumer', {
          toLocaleString: false
        })
      }
    }
  }
  // methods: {}
}
