export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const GET_RELATED_ORDERS_SUCCESS = 'GET_RELATED_ORDERS_SUCCESS'
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const FETCH_PAYMENT_PARAMS_SUCCESS = 'FETCH_PAYMENT_PARAMS_SUCCESS'
export const FETCH_EXPRESS_MAP_PARAMS_SUCCESS = 'FETCH_EXPRESS_MAP_PARAMS_SUCCESS'
export const GET_UPDATED_ORDER_SUCCESS = 'GET_UPDATED_ORDER_SUCCESS'
export const FETCH_ORDER_ADJUSTMENTS_SUCCESS = 'FETCH_ORDER_ADJUSTMENTS_SUCCESS'
export const FETCH_ORDER_REFUNDS_SUCCESS = 'FETCH_ORDER_REFUNDS_SUCCESS'
export const GET_ORDER_REFUND_SUCCESS = 'GET_ORDER_REFUND_SUCCESS'
export const FETCH_ORDER_INVOICES_SUCCESS = 'FETCH_ORDER_INVOICES_SUCCESS'
export const FETCH_ORDER_CREDIT_NOTES_SUCCESS = 'FETCH_ORDER_CREDIT_NOTES_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
